import React from 'react';
import { ButtonTypeVariants } from './Button';
import { PrimaryButton } from './PrimaryButton';
export type ButtonSize = 'sm' | 'md' | 'lg';
import clsx from 'clsx';
import styles from './ButtonVariants.module.scss';
interface ButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  variant: ButtonTypeVariants;
  hasMargin?: boolean;
  fillIcon?: boolean;
  bold?: boolean;
  fontWeight?: string;
  children: React.ReactNode;
  noDisabledStyle?: boolean;
}
export const ButtonVariants = ({
  size = 'md',
  variant = 'primary',
  hasMargin = false,
  bold = false,
  fontWeight = '500',
  noDisabledStyle,
  className,
  children,
  ...rest
}: ButtonWrapperProps) => {
  return <PrimaryButton bold={bold} hasMargin={hasMargin} size={size} {...rest} className={clsx({
    [String(styles.success)]: variant === 'success',
    [String(styles.secondary)]: variant === 'secondary',
    [String(styles.tertiary)]: variant === 'tertiary',
    [String(styles.gold)]: variant === 'gold',
    [String(styles.goldOutline)]: variant === 'gold-outline',
    [String(styles.noDisabledStyle)]: !noDisabledStyle
  }, className)}>
      {children}
    </PrimaryButton>;
};