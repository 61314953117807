import Button from 'components/Buttons/Button';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setCookiesAccepted } from 'redux/slices/browserPreferenceSlice';
import { BottomBanner } from './BottomBanner';
const CookieBanner = () => {
  const {
    t
  } = useTranslation();
  const {
    cookiesAccepted
  } = usePreference();
  const dispatch = useDispatch();
  const handleAccept = useCallback(() => {
    dispatch(setCookiesAccepted(true));
  }, [dispatch]);
  if (cookiesAccepted) return null;
  return <BottomBanner icon={'🍪'} title={t('txtAcceptCookies')} buttonTitle={t('btnAccept')} onClick={handleAccept} linkButton={<Link href="/info/privacy" replace>
          <Button variant="tertiary" value={t('btnLearnMore')} />
        </Link>} />;
};
export default CookieBanner;