import { RaceTypeTranslationText } from 'constants/race';
import { useGetLotteryDrawQuery, useGetRaceLeaderBoardV2Query } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { lotteryClient } from '../../apollo/clients';
import { ModalTypes } from '../../constants/modal';
import { formatCompatNumber } from '../../utils/formatCompatNumber';
import { getCountdown } from '../../utils/timeStuff';
import { useFormatExpiryText } from '../Chat/useFormatExpiryText';
import { Loader } from '../Loader';
import { MODAL_NAME, Modal } from '../Modal/Modal';
import { ModalLoaderContent } from '../Modal/ModalLoadContent';
import { NavigationLink } from './NavigationLink';
import { NavigationLinkWrapper } from './NavigationLinkWrapper';
import styles from './NavigationLotteryAndWeekly.module.scss';
const RaceInfoModal = dynamic(() => import('../modals/VipRewards/RaceInfoModal'), {
  loading: ModalLoaderContent
});
type Props = {
  isExpanded: boolean;
  className?: string;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
export function NavigationLotteryAndWeekly({
  isExpanded,
  className,
  raceLeaderBoardResult
}: Props) {
  const [currentModal, setCurrentModal] = useState<ModalTypes | ''>('');
  const {
    t
  } = useTranslation();
  const lotteryEnabled = process.env.NEXT_PUBLIC_LOTTERY_ENABLED === 'true';
  const {
    data: lotteryDrawData,
    loading: lotteryDrawLoading
  } = useGetLotteryDrawQuery({
    client: lotteryClient,
    skip: !lotteryEnabled
  });
  const activeRace = raceLeaderBoardResult?.data?.raceLeaderBoardV2.race ?? null;
  const {
    remaining
  } = useFormatExpiryText(new Date(activeRace?.endAt ?? Date.now()));
  let lotteryTimeRemain = '';
  const {
    days,
    hours,
    minutes
  } = getCountdown(lotteryDrawData?.lotteryDraw.drawAt);
  if (days) {
    lotteryTimeRemain = `${days}d`;
  } else if (hours) {
    lotteryTimeRemain = `${hours}h`;
  } else if (minutes) {
    lotteryTimeRemain = `${minutes}m`;
  }
  if (!lotteryDrawData?.lotteryDraw && !lotteryDrawLoading && !raceLeaderBoardResult?.loading && !activeRace) {
    return null;
  }
  return <>
      <Modal onClose={() => setCurrentModal('')} isOpen={!!currentModal} target={MODAL_NAME}>
        {currentModal && <RaceInfoModal />}
      </Modal>

      <div className={`${styles.root} ${isExpanded ? styles.isExpanded : ''}`}>
        {lotteryEnabled && lotteryDrawLoading && <NavigationLinkWrapper className={className}>
            <Loader variant="circle" className={styles.loader} />
          </NavigationLinkWrapper>}

        {lotteryEnabled && lotteryDrawData?.lotteryDraw && !lotteryDrawLoading && <NavigationLinkWrapper className={className}>
            <NavigationLink iconUrl="/icons/8.svg" path="/lottery" pathStartWith="/lottery" isExpanded={isExpanded} title={'Shuffle Lottery'} className={styles.lottery} counterVariant={'text'} counter={lotteryTimeRemain ? <span className={styles.date}>{lotteryTimeRemain}</span> : undefined} />
          </NavigationLinkWrapper>}

        {raceLeaderBoardResult?.loading && <NavigationLinkWrapper className={className}>
            <Loader variant="circle" className={styles.loader} />
          </NavigationLinkWrapper>}

        {activeRace && !raceLeaderBoardResult?.loading && <NavigationLinkWrapper className={className}>
            <NavigationLink iconUrl="/icons/trophy.svg" onClick={() => {
          setCurrentModal(ModalTypes.RACE_INFO);
        }} disabled={isExpanded} isExpanded={isExpanded} title={`$${formatCompatNumber(activeRace.totalPrizeUsd)} ${t(RaceTypeTranslationText[activeRace.type])}`} counterVariant="text" counter={<span>{remaining}</span>} />
          </NavigationLinkWrapper>}
      </div>
    </>;
}