import { Currency } from 'generated/graphql';
import React from 'react';
import { CURRENCY_CONFIGS } from 'constants/currency';
import { RenderClient } from '../RenderClient';
import styles from './CryptoIcon.module.scss';
const CryptoIcon = ({
  currency,
  width,
  height,
  className
}: {
  currency: Currency;
  className?: string;
  width?: number;
  height?: number;
}) => {
  const iconSrc = CURRENCY_CONFIGS[currency].icon;
  return <RenderClient>
      <div className={`${styles.root} ${className || ''}`}>
        <img className={styles.image} src={`/icons/crypto/${iconSrc}`} alt={currency} loading="lazy" width={width || 16} height={height || 16} />
      </div>
    </RenderClient>;
};
export default React.memo(CryptoIcon);