import React, { InputHTMLAttributes, ReactNode } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { FormControlWrapper } from './FormControlWrapper';
import { Label } from './Label';
import { Relative } from '../Relative';
import styles from './Checkbox.module.scss';
export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Input label
   */
  label?: string | JSX.Element | ReactNode;
  /**
   * Error message
   */
  error?: string;
  className?: string;
  classNames?: Partial<{
    root: string;
  }>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
}
/**
 * Primary UI component for user interaction
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  error,
  className,
  labelProps,
  inputProps,
  classNames,
  ...rest
}: CheckboxProps, ref) => {
  return <FormControlWrapper className={classNames?.root}>
        <Label className={`${styles.root} ${className ?? ''}`} {...labelProps}>
          <input className={styles.checkboxInput} ref={ref} type="checkbox" {...inputProps} {...rest} />
          <span className={styles.checkboxCheckMark} />
          <Relative className={className}>{label}</Relative>
        </Label>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControlWrapper>;
});
export default Checkbox;