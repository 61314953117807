import BigNumber from 'bignumber.js';

export const MAX_RESULT_COUNT = 6; // 5 are shown, 1 before and after for animation
export const EDGE_BPS = 100; // all original games have 1% edge

// for autobet multi-action games
export const AUTOBET_VIEWING_DELAY = 1000;
export const AUTOBET_RESET_DELAY = 200;
export const TURBO_AUTOBET_VIEWING_DELAY = 400;
export const TURBO_AUTOBET_RESET_DELAY = 100;

export enum GameOutcome {
  WIN = 'WIN',
  LOSE = 'LOSE',
  DRAW = 'DRAW',
}
export interface IGameResult {
  betId: string;
  gameResult: string;
  gameOutcome: GameOutcome;
}

export enum InputErrors {
  UNDER = 0,
  NONE = 1,
  OVER = 2,
}

export interface IOriginalPlayResult {
  PnL: BigNumber;
  gameOutcome: GameOutcome;
  isFailed?: boolean;
}

export function getGameOutcome(multiplierBN: BigNumber) {
  if (multiplierBN.isEqualTo(1)) {
    return GameOutcome.DRAW;
  }

  return multiplierBN.isGreaterThan(1) ? GameOutcome.WIN : GameOutcome.LOSE;
}
