import BigNumber from 'bignumber.js';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import { Currency, useGetMyBalanceLazyQuery } from 'generated/graphql';
import { useCallback } from 'react';

import {
  CurrencyMapping,
  DEFAULT_BALANCES,
  useBalanceUpdates,
} from 'hooks/wallet/useBalanceUpdates';
import { useStore } from '../useStore';

export type BalanceOrigin = 'vault' | 'wallet';

// Always returns in Crypto form
export const useBalances = (): CurrencyMapping => {
  const { state: balancesDeltas } = useStore(state => state.balances.balancesDeltas);
  const { state: values } = useStore(state => state.balances.values);

  const balancesShown = { ...values };

  for (const currency in balancesDeltas) {
    balancesShown[currency as Currency] = BigNumber(balancesShown[currency as Currency])
      .plus(balancesDeltas[currency as Currency] ?? 0)
      .toFixed(CRYPTO_DECIMALS);
  }

  return balancesShown;
};

export const useFetchBalances = () => {
  const [getBalance] = useGetMyBalanceLazyQuery({ fetchPolicy: 'no-cache' });
  const { updateBalances } = useBalanceUpdates();

  const fetchBalances = useCallback(async () => {
    const balances = (await getBalance()).data?.me.account.balances;
    if (balances) {
      updateBalances(balances);
    }
  }, [updateBalances, getBalance]);

  return { fetchBalances };
};

export const useWalletBalance = (currency: Currency): string => {
  const { state: walletBalance } = useStore(state =>
    BigNumber(state.balances.values[currency])
      .plus(state.balances.balancesDeltas[currency] ?? 0)
      .toFixed(CRYPTO_DECIMALS),
  );

  return walletBalance;
};

export const useBalance = (currency: Currency, origin: BalanceOrigin = 'wallet'): string => {
  const {
    state: { walletBalance, vaultBalances },
  } = useStore(state => ({
    walletBalance: BigNumber(state.balances.values[currency])
      .plus(state.balances.balancesDeltas[currency] ?? 0)
      .toFixed(CRYPTO_DECIMALS),
    vaultBalances: state.profile.account.vaultBalances,
  }));

  const _balances = { ...DEFAULT_BALANCES };

  for (const balance of vaultBalances) {
    _balances[balance.currency] = balance.amount;
  }

  const vaultBalance = _balances[currency];

  if (origin === 'vault') {
    return vaultBalance;
  }

  return walletBalance;
};

export const useOverrideBalances = () => {
  const { updateBalance, adjustBalanceButRetainTotal, changeOverrideBalance } = useBalanceUpdates();

  const updateOverrideBalance = useCallback(
    (deltaAmount: BigNumber, currency: Currency) => {
      changeOverrideBalance({
        deltaAmount: deltaAmount.toFixed(CRYPTO_DECIMALS),
        currency,
      });
    },
    [changeOverrideBalance],
  );

  const updateBalanceAndDelta = useCallback(
    (amount: BigNumber, currency: Currency, newDeltaAmount?: BigNumber) => {
      updateBalance({
        currency,
        amount: amount.toFixed(CRYPTO_DECIMALS),
        newDeltaAmount: newDeltaAmount ? newDeltaAmount.toFixed(CRYPTO_DECIMALS) : undefined,
      });
    },
    [updateBalance],
  );

  const updatePlinkoBalance = useCallback(
    (balance: BigNumber, currency: Currency) => {
      adjustBalanceButRetainTotal({
        currency,
        newBalance: balance.toFixed(CRYPTO_DECIMALS),
      });
    },
    [adjustBalanceButRetainTotal],
  );

  return { updateOverrideBalance, updateBalanceAndDelta, updatePlinkoBalance };
};
