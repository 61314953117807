import { ApolloLink, createHttpLink, from, fromPromise, split, toPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { captureMessage } from '@sentry/nextjs';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { sleep } from 'utils/sleep';

import { DEV_TOOL_IDS } from '../components/SportsDevToolContent';
import { customFetch } from './custom-fetch';
import {
  genCorrelationId,
  geoRestrictionAlert,
  getAccessToken,
  getRefreshedTokenPromise,
  setUserCountry,
} from './utils';

const accessTokenPromises: Record<
  string,
  Promise<{
    accessToken: string;
  }>
> = {};

function getCFAccessToken(): Object {
  const clientId = process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID;
  const clientSecret = process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET;

  if (clientId && clientSecret) {
    return {
      'CF-Access-Client-Id': clientId,
      'CF-Access-Client-Secret': clientSecret,
    };
  }

  return {};
}

function getCachedAccessToken(accessToken: string) {
  accessTokenPromises[accessToken] = accessTokenPromises[accessToken] ?? getRefreshedTokenPromise();
  return accessTokenPromises[accessToken];
}

export const countryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();

    const country = context.response.headers.get('x-country');

    setUserCountry(country);

    return response;
  });
});

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const errorCode = err.message;

      switch (errorCode) {
        case 'GEO_REGION_RESTRICTED': {
          geoRestrictionAlert();
          break;
        }
        case 'UNAUTHENTICATED': {
          const oldAccessToken: string =
            operation.getContext().headers.Authorization.split(' ')[1] ?? 'accessToken';

          return fromPromise(
            (
              getCachedAccessToken(oldAccessToken) as Promise<{
                accessToken: string;
              }>
            ).then(refreshResponse => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${refreshResponse?.accessToken}` || '',
                },
              }));
              return toPromise(forward(operation));
            }),
          );
        }
        default:
          console.error(`[GraphQL error]: Message: ${err.message}`);
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError && typeof window !== 'undefined') {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();
  return {
    headers: {
      ...headers,
      ...getCFAccessToken(),
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'X-Correlation-Id': genCorrelationId(),
    },
  };
});

export const nonAuthLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getCFAccessToken(),
      'X-Correlation-Id': genCorrelationId(),
    },
  };
});

export const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  fetch: customFetch as typeof fetch,
});

export const sportsHttpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_SPORTS_GRAPHQL_API,
  fetch: customFetch as typeof fetch,
});

export const lotteryHttpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_LOTTERY_GRAPHQL_API,
  fetch: customFetch as typeof fetch,
});

export const kycHttpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_KYC_GRAPHQL_API,
  fetch: customFetch as typeof fetch,
});

const RETRY_COUNT = 100; // stop retrying after 100 times, something clearly wrong
const LOG_COUNT = 10;
const TIME_OUT_CONNECTING = 10_000;
let CONNECTING_TIMER = 0;
let timedOut: NodeJS.Timeout;
let closedEvent: unknown = null;
const errorEvent: unknown = null;
let retriedTimes: number | null = null;
let connectingTimerReported = false;
export let sportsWebSocketCorrelationId = '';
export let socketStatus = '';

async function checkScreenVisible() {
  while (document.visibilityState !== 'visible') {
    await sleep(1000);
  }
}

function updateDevTool(text: string) {
  const element = document.getElementById(DEV_TOOL_IDS.socket);
  socketStatus = text;

  if (element) {
    element.innerText = text;
  }
}

function createWsLink(url: string) {
  const isSports = process.env.NEXT_PUBLIC_SUBSCRIPTIONS_API_SPORTS === url;

  const link =
    typeof window !== 'undefined'
      ? new GraphQLWsLink(
          createClient({
            url,
            connectionParams: () => {
              const accessToken = getAccessToken();
              const correlationId = genCorrelationId() || '';

              if (isSports) {
                sportsWebSocketCorrelationId = correlationId;
              }

              return {
                'x-correlation-id': correlationId,
                authorization: accessToken,
              };
            },
            keepAlive: 10_000,
            connectionAckWaitTimeout: 5_000,
            retryAttempts: RETRY_COUNT,
            on: {
              ...(isSports
                ? {
                    connected: () => {
                      clearTimeout(CONNECTING_TIMER);
                      updateDevTool('Connected');
                    },
                    error: error => {
                      clearTimeout(CONNECTING_TIMER);
                      closedEvent = error;
                      updateDevTool('Error');
                    },
                    connecting: () => {
                      if (connectingTimerReported || !navigator.onLine) {
                        return;
                      }

                      clearTimeout(CONNECTING_TIMER);

                      CONNECTING_TIMER = window.setTimeout(() => {
                        if (navigator.onLine) {
                          captureMessage('Sports connection timeout', {
                            extra: {
                              sportsWebSocketCorrelationId,
                              closedEvent: JSON.stringify(closedEvent),
                              errorEvent: JSON.stringify(errorEvent),
                              retriedTimes,
                            },
                            level: 'error',
                          });
                          connectingTimerReported = true;
                        }
                      }, TIME_OUT_CONNECTING);
                      updateDevTool('Connecting');
                    },
                    closed: event => {
                      clearTimeout(CONNECTING_TIMER);
                      closedEvent = event;
                      updateDevTool('Closed');
                    },
                    opened: () => {
                      updateDevTool('Opened');
                    },
                  }
                : {}),
              ping: received => {
                if (!received /* sent */) {
                  timedOut = setTimeout(() => {
                    // a close event `4499: Terminated` is issued to the current WebSocket and an
                    // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                    // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                    //
                    // calling terminate is not considered fatal and a connection retry will occur as expected
                    //
                    // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                    link?.client.terminate();
                  }, 5_000);
                }
              },
              pong: received => {
                if (received) {
                  clearTimeout(timedOut);
                }
              },
            },
            retryWait: async (numRetry: number) => {
              retriedTimes = numRetry;
              // Log retries, keeps on retrying but gets slightly longer each time
              // numRetry starts at 0, so we should start at 1
              const waitTime = Math.log(numRetry / 10 + 1) * 50_000; // 4.7s, 9.11s, 13.1s ... for total of 8248 seconds - 2.5 hours

              await sleep(waitTime);

              // Make sure we are not retrying if the user doesnt have the tab open
              await checkScreenVisible();

              if (numRetry >= RETRY_COUNT) {
                captureMessage('subscription retry count reached maximum');
              } else if (numRetry > LOG_COUNT) {
                captureMessage(`subscription retry exceeded ${numRetry}`);
              }
            },
            shouldRetry: () => true,
          }),
        )
      : null;

  return link;
}

export const wsLink = createWsLink(process.env.NEXT_PUBLIC_SUBSCRIPTIONS_API as string);

export const wsLinkStable = createWsLink(
  process.env.NEXT_PUBLIC_SUBSCRIPTIONS_API_STABLE as string,
);

export const wsLinkSports = createWsLink(
  process.env.NEXT_PUBLIC_SUBSCRIPTIONS_API_SPORTS as string,
);

/**
 * We split the link because NextJS SSR tries to create client server-side.
 * WebSocket is only available client-side so it's conditional on `window` existing.
 */
export const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        from([countryLink, httpLink]),
      )
    : httpLink;

export const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_KYC_GRAPHQL_API,
});
