export const ANIMATE_BOTTOM_TO_UP = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
};

export const ANIMATE_BOTTOM_TO_UP_SHORT = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  ease: {
    ease: 'linear',
    duration: 0.2,
  },
};

export const ANIMATE_SLIDE_UP = {
  initial: { y: '100vh' },
  animate: { y: 0 },
  duration: 0.3,
  ease: 'anticipate',
};

export const ANIMATE_UP_TO_BOTTOM = {
  initial: { opacity: 0, y: -50 },
  animate: { opacity: 1, y: 0 },
};

export const ANIMATE_SCALE_TO_UP = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
};

export const ANIMATE_FADE_IN = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};
