export enum SPORT_BET_ERRORS_LIST {
  TOTAL_ODDS_IS_CHANGED = 'TOTAL_ODDS_IS_CHANGED',
  SPORTS_BET_IS_CLOSED = 'SPORTS_BET_IS_CLOSED',
  SPORTS_MARKET_CASHOUT_IS_NOT_OPEN = 'SPORTS_MARKET_CASHOUT_IS_NOT_OPEN',
  SPORTS_BET_ALREADY_CANCELLED = 'SPORTS_BET_ALREADY_CANCELLED',
  SPORTS_BET_ALREADY_VOIDED = 'SPORTS_BET_ALREADY_VOIDED',
  SPORTS_BET_SELECTION_ALREADY_PUSHED = 'SPORTS_BET_SELECTION_ALREADY_PUSHED',
  SPORTS_BET_SELECTION_ALREADY_VOIDED = 'SPORTS_BET_SELECTION_ALREADY_VOIDED',
  SPORTS_BET_SELECTION_ALREADY_CASHED_OUT = 'SPORTS_BET_SELECTION_ALREADY_CASHED_OUT',
  SPORTS_MARKET_CASHOUT_NOT_ENABLED = 'SPORTS_MARKET_CASHOUT_NOT_ENABLED',
  CASHOUT_ODDS_IS_CHANGED = 'CASHOUT_ODDS_IS_CHANGED',
  CASHOUT_FAILED = 'CASHOUT_FAILED',
  CASHOUT_INPLAY_ODDS_CHANGED = 'CASHOUT_INPLAY_ODDS_CHANGED',
  CASHOUT_INPLAY_STATUS_CHANGED = 'CASHOUT_INPLAY_STATUS_CHANGED',
  SPORTS_FIXTURE_NOT_TRADABLE = 'SPORTS_FIXTURE_NOT_TRADABLE',
  SPORTS_FIXTURE_ALREADY_STARTED = 'SPORTS_FIXTURE_ALREADY_STARTED',
  SPORTS_MARKET_SELECTIONS_NOT_TRADABLE = 'SPORTS_MARKET_SELECTIONS_NOT_TRADABLE',
  SPORTS_MARKET_NOT_TRADABLE = 'SPORTS_MARKET_NOT_TRADABLE',
  SPORTS_MARKET_CLOSED = 'SPORTS_MARKET_CLOSED',
  SPORTS_MARKET_SELECTIONS_DISABLED = 'SPORTS_MARKET_SELECTIONS_DISABLED',
  SPORTS_MARKET_SELECTIONS_RESULTED = 'SPORTS_MARKET_SELECTIONS_RESULTED',
  SPORTS_MARKET_SELECTIONS_IN_SAME_FIXTURE = 'SPORTS_MARKET_SELECTIONS_IN_SAME_FIXTURE',
  BET_ASSESSMENT_API_FAILED = 'BET_ASSESSMENT_API_FAILED',
  BET_ASSESSMENT_FAILED = 'BET_ASSESSMENT_FAILED',
  BET_ASSESSMENT_ODDS_CHANGED = 'BET_ASSESSMENT_ODDS_CHANGED',
  BET_ASSESSMENT_STATUS_CHANGED = 'BET_ASSESSMENT_STATUS_CHANGED',
  USER_NOT_ALLOWED_SPORTS_BET = 'USER_NOT_ALLOWED_SPORTS_BET',
  CROSS_PROVIDERS_NOT_ALLOWED_SPORTS_MULTI_BET = 'CROSS_PROVIDERS_NOT_ALLOWED_SPORTS_MULTI_BET',
  ODDIN_OTS_REJECTED = 'ODDIN_OTS_REJECTED',
  ODDIN_OTS_TIMEOUT = 'ODDIN_OTS_TIMEOUT',
  IN_RECOVERY_MODE = 'IN_RECOVERY_MODE',
}

export const ERRORS_LIST = Object.keys(SPORT_BET_ERRORS_LIST);
