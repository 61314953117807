import { AuthModalTabType, AuthModalType } from 'constants/modal';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsLoggedIn } from '../../hooks/auth/useIsLoggedIn';
import isString from '../../utils/isString';
import { ModalOnClose, ModalType, ModalUrl, QueryTypes } from './GlobalModal.type';
import { Modal } from './Modal';
import { ModalLoaderContent } from './ModalLoadContent';
import { closeGlobalModal } from './closeGlobalModal';
import { openGlobalModal } from './openGlobalModal';
import { kycClient } from 'apollo/clients';
import { TotpPrompt } from 'components/modals/Wallet/components/TotpPrompt';
import { KycLevelStatus, useGetMyKycQuery } from 'generated/graphql';
import { useTwoFA } from 'hooks/auth/useTwoFA';
import { useStore } from 'hooks/useStore';
import { useSelector } from 'react-redux';
import styles from './GlobalModal.module.scss';
const BetResultsModal = dynamic(() => import('../modals/BetResult/BetResultsModal'), {
  loading: ModalLoaderContent
});
const CrashGamePublicResultModal = dynamic(() => import('../modals/CrashGamePublicResultModal'), {
  loading: ModalLoaderContent
});
const SportMaintenance = dynamic(() => import('../modals/SportMaintenance').then(mod => mod.SportMaintenance), {
  loading: ModalLoaderContent
});
const SportBetResultsModal = dynamic(() => import('../modals/SportBetResult/SportBetResultsModal'), {
  loading: ModalLoaderContent
});
const WalletModal = dynamic(() => import('../modals/Wallet/WalletModal'), {
  loading: ModalLoaderContent
});
const AuthModal = dynamic(() => import('../modals/Authentication/AuthModal'), {
  loading: ModalLoaderContent
});
const UserInfoModal = dynamic(() => import('../modals/User/UserInfoModal'), {
  loading: ModalLoaderContent
});
const ChallengePrizeModal = dynamic(() => import('../modals/VipRewards/ChallengePrizeModal'), {
  loading: ModalLoaderContent
});
const VaultModal = dynamic(() => import('../modals/VaultModal/VaultModal'), {
  loading: ModalLoaderContent
});
const PromoRedeemModal = dynamic(() => import('../modals/PromoRedeemModal'), {
  loading: ModalLoaderContent
});
const RedeemCodeLookupModal = dynamic(() => import('../modals/PromoRedeemModal/RedeemCodeLookupModal'), {
  loading: ModalLoaderContent
});
const LotteryTicketModal = dynamic(() => import('../Lottery/LotteryGlobalTicketModal'), {
  loading: ModalLoaderContent
});
const ConvertModal = dynamic(() => import('../modals/ConvertModal/ConvertModal'), {
  loading: ModalLoaderContent
});
const DEFAULT_MODAL: ModalType = {
  type: ModalUrl.BET,
  id: '',
  isOpen: false,
  name: ('' as const),
  currency: ('' as const),
  amount: ('' as const)
};
const MDOALS_WITH_TOTP_PROMP = [ModalUrl.WALLET, ModalUrl.VAULT];
export function GlobalModal() {
  const [modal, setModal] = useState<ModalType>(DEFAULT_MODAL);
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const {
    isTotpEnabled
  } = useTwoFA();
  const {
    query,
    pathname,
    push
  } = router;
  const routerRef = useRef(router);
  const sportsBetMaintenance = useSelector((state: AppState) => state.globalState.sportsBetMaintenanceShow);
  const {
    state: emailVerifiedAt
  } = useStore(state => state.profile.emailVerifiedAt);
  const {
    data,
    loading: kycLoading
  } = useGetMyKycQuery({
    client: kycClient,
    skip: isTotpEnabled || !MDOALS_WITH_TOTP_PROMP.includes(modal.type)
  });
  const kycDone = data?.kyc?.level1Status === KycLevelStatus.COMPLETED;
  // only show totp prompt if kyc is done, totp is not enabled, modal is one of those that shows prompt and email is verified
  const showTotpPromptFooter = !kycLoading && kycDone && Boolean(emailVerifiedAt);
  const tabTypeQuery = (query[QueryTypes['md-tab']] as ModalType['tab']);
  const idQuery = query[QueryTypes['md-id']];
  const modalQuery = (query[QueryTypes.modal] as ModalType['type']);
  const nameQuery = query[QueryTypes['md-name']];
  const currencyQuery = query[QueryTypes['md-currency']];
  const amountQuery = query[QueryTypes['md-amount']];
  const resetTokenQuery = query[QueryTypes.resetToken];
  routerRef.current = router;
  const onClose: ModalOnClose = useCallback(() => {
    const shouldResetRoute = tabTypeQuery === AuthModalType.SessionExpired || tabTypeQuery === AuthModalType.ResetPassword;
    if (shouldResetRoute) {
      push('/').catch();
      return;
    }
    setModal(DEFAULT_MODAL);
    closeGlobalModal({
      router: routerRef.current
    });
  }, [push, tabTypeQuery]);
  useEffect(() => {
    const triggerModalOpenByQueryString = () => {
      if (!isString(modalQuery)) {
        return;
      }
      switch (modalQuery) {
        case ModalUrl.WALLET:
        case ModalUrl.AUTH:
        case ModalUrl.VAULT:
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true,
            tab: tabTypeQuery,
            ...(isString(nameQuery) && nameQuery ? {
              name: nameQuery
            } : {}),
            ...(modalQuery === ModalUrl.AUTH ? {
              classNames: {
                modalContent: styles.authModalContent,
                modalBody: styles.authModalBody
              }
            } : {
              classNames: {
                modalBody: styles.walletModalBody
              }
            })
          });
          break;
        case ModalUrl.SPORT_BET:
          if (isString(idQuery) && !sportsBetMaintenance) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
              classNames: {
                modalBody: styles.betModalBody
              }
            });
          }
          break;
        case ModalUrl.BET:
        case ModalUrl.CRASH_GAME:
          if (isString(idQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
              classNames: {
                modalBody: styles.betModalBody
              }
            });
          }
          break;
        case ModalUrl.USER:
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true,
            classNames: {
              modalBody: styles.userModalBody
            }
          });
          break;
        case ModalUrl.CHALLENGE_PRIZE:
          if (isString(idQuery) && isString(nameQuery) && isString(currencyQuery) && isString(amountQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
              name: nameQuery,
              currency: currencyQuery,
              amount: amountQuery
            });
          }
          break;
        case ModalUrl.SPORT_MAINTENANCE:
          setModal({
            ...DEFAULT_MODAL,
            type: ModalUrl.SPORT_MAINTENANCE,
            isOpen: true
          });
          break;
        case ModalUrl.RESET_PASSWORD:
          setModal({
            ...DEFAULT_MODAL,
            type: ModalUrl.RESET_PASSWORD,
            isOpen: true
          });
          break;
        case ModalUrl.LOTTERY:
          setModal({
            ...DEFAULT_MODAL,
            type: ModalUrl.LOTTERY,
            tab: tabTypeQuery,
            isOpen: true,
            id: isString(idQuery) ? idQuery : undefined,
            classNames: {
              mobileModal: styles.lotteryVieModal
            }
          });
          break;
        case ModalUrl.PROMO:
        case ModalUrl.CONVERT:
        case ModalUrl.REDEEM:
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true
          });
          break;

        // search modal is custom
        case ModalUrl.SEARCH:
          break;
        default:
          (modalQuery satisfies never);
      }
    };
    triggerModalOpenByQueryString();
  }, [amountQuery, currencyQuery, idQuery, modalQuery, nameQuery, tabTypeQuery, sportsBetMaintenance]);
  useEffect(() => {
    const closeExistingModalWithQueryString = () => {
      if (modal.isOpen && !modalQuery) {
        setModal(DEFAULT_MODAL);
      }
    };
    closeExistingModalWithQueryString();
  }, [modal.isOpen, modalQuery]);
  useEffect(() => {
    if (!isString(resetTokenQuery) || !resetTokenQuery || !pathname.includes('reset-password')) {
      return;
    }
    if (isLoggedIn) {
      routerRef.current.push(`/settings/security?token=${resetTokenQuery}&modal=${ModalUrl.RESET_PASSWORD}`).catch();
    } else {
      openGlobalModal({
        router: routerRef.current,
        type: ModalUrl.AUTH,
        tab: AuthModalType.ResetPassword
      });
    }
  }, [isLoggedIn, pathname, resetTokenQuery]);
  useEffect(() => {
    if (router.query.r && !isLoggedIn) {
      // The campaign code is handled in the useCampaignCode hook and will be filled into the campaign code field of the register form
      openGlobalModal({
        type: ModalUrl.AUTH,
        router: routerRef.current,
        tab: AuthModalTabType.REGISTER
      });
    }
  }, [isLoggedIn, router.query.r]);
  if (pathname === '/404') {
    return null;
  }
  return <Modal classNames={modal.classNames} isOpen={modal.isOpen} onClose={onClose} footer={showTotpPromptFooter ? <TotpPrompt /> : undefined}>
      {modal.type === ModalUrl.SPORT_MAINTENANCE && <SportMaintenance onClose={onClose} />}
      {modal.type === ModalUrl.SPORT_BET && modal.id && <SportBetResultsModal betId={modal.id} onClose={onClose} />}
      {modal.type === ModalUrl.BET && modal.id && <BetResultsModal betId={modal.id} onClose={onClose} />}
      {modal.type === ModalUrl.WALLET && <WalletModal tabType={modal.tab} username={modal.name} onClose={onClose} />}
      {modal.type === ModalUrl.AUTH && <AuthModal onClose={onClose} tabType={modal.tab} />}
      {modal.type === ModalUrl.USER && <UserInfoModal />}
      {modal.type === ModalUrl.LOTTERY && <LotteryTicketModal id={modal.id} tabType={modal.tab} />}
      {modal.type === ModalUrl.CHALLENGE_PRIZE && <ChallengePrizeModal onClose={onClose} />}
      {modal.type === ModalUrl.VAULT && <VaultModal />}
      {modal.type === ModalUrl.PROMO && <PromoRedeemModal onClose={onClose} />}
      {modal.type === ModalUrl.REDEEM && <RedeemCodeLookupModal onClose={onClose} />}
      {modal.type === ModalUrl.CRASH_GAME && modal.id && <CrashGamePublicResultModal crashId={modal.id} onClose={onClose} />}
      {modal.type === ModalUrl.CONVERT && <ConvertModal onClose={onClose} />}
    </Modal>;
}