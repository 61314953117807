import Button from 'components/Buttons/Button';
import { createOption } from 'constants/currency';
import { FiatCurrency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFiatPreference } from 'redux/slices/browserPreferenceSlice';
import { Select, SelectOption } from 'components/FormControls/Select';
import styles from './CurrencyModal.module.scss';
const CurrencyModal = ({
  onClose
}: {
  onClose: () => void;
}) => {
  const {
    fiatPreference
  } = usePreference();
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState<FiatCurrency>(fiatPreference);
  const fiatCurrencyOptions = (Object.keys(FiatCurrency).map(fiat => createOption({
    fiatCurrency: (fiat as FiatCurrency),
    name: t(fiat)
  })) as SelectOption<FiatCurrency>[]);
  return <>
      <h3 className={styles.heading}>{t('currencyHeader')}</h3>
      <p className={styles.subHeading}>{t('currencySubheader')}</p>
      <div className={styles.form}>
        <Select name="currency-select" options={fiatCurrencyOptions} value={selectedCurrency} onChange={setSelectedCurrency} />
      </div>

      <Button value={t('savePreference')} onClick={() => {
      dispatch(setFiatPreference(selectedCurrency));
      onClose();
    }} />
    </>;
};
export default CurrencyModal;