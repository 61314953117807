import dayjs from 'dayjs';
import { TFunction } from 'next-i18next';
import { EventState, Sports, SportsMatchPhase, SportsMatchState } from '../../generated/graphql';
import { convertMatchSetNumber, getEventState } from '../sportsGenerator';
import { MATCH_PHASE_LABEL_LIST, MATCH_PHASE_LIST } from './liveMatchProgressText';

export type LiveMatchProgressText = {
  content: string;
  parts: string[];
};

function getTimeDisplayText({ time, hasSeconds }: { time: number; hasSeconds?: boolean }) {
  const minutes = dayjs.duration(time).asMinutes();
  const timeDisplayByMinutes = `${minutes <= 1 ? 1 : Math.floor(minutes)}'`;
  return hasSeconds ? dayjs.duration(time).format('mm:ss') : timeDisplayByMinutes;
}

const getLiveProgressText = ({
  matchState,
  t,
  sports,
}: {
  matchState: SportsMatchState | null;
  t: TFunction;
  sports: Sports;
}): LiveMatchProgressText => {
  const matchPhase = matchState?.matchSummary?.matchPhase;
  const matchStatusDisplay = matchState?.matchSummary?.matchStatusDisplay;
  const timeRemaining = matchState?.matchSummary?.timeRemaining ?? null;
  const timeElapsed = matchState?.matchSummary?.timeElapsed ?? null;

  let timeDisplayText = '';

  if (timeElapsed !== null && timeElapsed > 0) {
    timeDisplayText = getTimeDisplayText({
      time: timeElapsed,
      hasSeconds: sports === Sports.EFOOTBALL,
    });
  } else if (timeRemaining !== null && timeRemaining > 0) {
    timeDisplayText = getTimeDisplayText({
      time: timeRemaining,
      hasSeconds: sports === Sports.EBASKETBALL,
    });
  }

  const content =
    matchStatusDisplay && Array.isArray(matchStatusDisplay)
      ? matchStatusDisplay
          .map(({ freeText, matchPhase, matchPhaseLabel, rawNumber }) => {
            if (matchPhase) {
              return t(MATCH_PHASE_LIST[matchPhase]);
            }
            if (matchPhaseLabel) {
              return t(MATCH_PHASE_LABEL_LIST[matchPhaseLabel]);
            }
            if (rawNumber) {
              return convertMatchSetNumber(rawNumber);
            }
            return freeText;
          })
          .join(' ')
      : '';

  if (!timeDisplayText) {
    return {
      content: content.trim(),
      parts: [],
    };
  }

  if (matchPhase && [SportsMatchPhase.HALFTIME, SportsMatchPhase.PENALTIES].includes(matchPhase)) {
    return {
      content: t(MATCH_PHASE_LIST[matchPhase]),
      parts: [],
    };
  }

  return {
    content: `${timeDisplayText} ${content}`.trim(),
    parts: [timeDisplayText, `${content}`],
  };
};

export const getLiveMatchProgressText = ({
  matchState,
  startTime,
  formattedDateTime,
  inPlayAllowed,
  sports,
  t,
}: {
  matchState?: SportsMatchState | null;
  startTime?: string;
  inPlayAllowed?: boolean | null;
  formattedDateTime?: string;
  sports?: Sports;
  t: TFunction;
}): LiveMatchProgressText => {
  if (!sports) {
    return {
      content: '',
      parts: [],
    };
  }

  const eventState = matchState?.matchSummary?.eventState;
  const isPreMatch = eventState === EventState.PREMATCH || dayjs(startTime).isAfter(dayjs());

  // Fixture may return match state even is not started, so make sure it's not pre match
  if (matchState && !isPreMatch) {
    return getLiveProgressText({ matchState, t, sports });
  }

  return {
    content: getEventState({
      startTime: startTime ?? '',
      inPlayAllowed,
      formattedDateTime: formattedDateTime ?? '',
      eventState,
    }),
    parts: [],
  };
};
