import BigNumber from 'bignumber.js';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { CRYPTO_DECIMALS, FIAT_DECIMALS, FIAT_SMALLEST_UNIT } from '../constants/decimalPlaces';
import { Currency } from '../generated/graphql';
import { getCurrencyLabel } from 'shufflecom-calculations/lib/utils/chain-currency-display';
import { useConversion } from './useConversion';
import usePreference from './usePreference';

/**
 * Realtime input conversions between crypto and fiat.
 */
export const useCurrencyInputConversion = (cryptoCurrency: Currency) => {
  const {
    displayInFiat,
    fiatPreference
  } = usePreference();
  const {
    t
  } = useTranslation();
  const {
    cryptoToFiat,
    fiatToCrypto
  } = useConversion(cryptoCurrency);
  const inputToCrypto = useCallback((value: string, maxValue?: string) => {
    if (!displayInFiat) return value;
    const cryptoValue = fiatToCrypto(value);
    // If max value is zero means balance is zero, no need to check
    if (maxValue && !BigNumber(maxValue).isZero() && BigNumber(cryptoValue).gt(maxValue)) {
      // Check if max balance, cause fiat rounding can exceed the max balance
      // Minus 1 balance
      const valueMinusOneUnit = BigNumber(value).minus(FIAT_SMALLEST_UNIT).toFixed();
      if (BigNumber(fiatToCrypto(valueMinusOneUnit)).lte(maxValue)) {
        return maxValue;
      }
    }
    return cryptoValue;
  }, [fiatToCrypto, displayInFiat]);
  const inputToLabelCurrency = useCallback((value: string, cryptoValue: string, invert = false) => {
    if (!value || value === '.') {
      value = '0';
      cryptoValue = '0';
    }
    let showCryptoLabel = displayInFiat;
    if (invert) {
      showCryptoLabel = !showCryptoLabel;
    }
    if (showCryptoLabel) {
      const cryptoAmount = t('intlNumber', {
        val: cryptoValue,
        minimumFractionDigits: CRYPTO_DECIMALS,
        maximumFractionDigits: CRYPTO_DECIMALS
      });
      return `${cryptoAmount} ${getCurrencyLabel(cryptoCurrency)}`;
    }
    const labelValue = t('intlNumber', {
      val: invert ? value : cryptoToFiat(value),
      minimumFractionDigits: FIAT_DECIMALS,
      maximumFractionDigits: FIAT_DECIMALS
    });
    return `${labelValue} ${fiatPreference}`;
  }, [cryptoToFiat, t, fiatPreference, cryptoCurrency, displayInFiat]);
  return {
    inputToCrypto,
    inputToLabelCurrency
  };
};