import { BetSlipErrors } from 'hooks/useSportsBetSlipCheck';

import {
  Currency,
  GetMaxSportsBetAmountUsdQuery,
  GetSportsMatchStatesQuery,
  PlaceSportsBetInput,
  SportsBetStatus,
} from '../../../generated/graphql';
import { OddsFormat } from '../../../redux/slices/browserPreferenceSlice';
import { BetSlipItem, BetSlipStatus, MultiBet } from '../../../redux/slices/sportsBetSlice.type';
import { getSportBetOdds } from '../../../utils/getSportBetOdds';
import { getBetSlipActionPanelData } from './BetTicket/getBetSlipActionPanelData';

export enum SportBetUISpecificStatus {
  TRADING = 'TRADING',
  DEAD_HEAT = 'DEAD_HEAT',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  LOSS = 'LOSS',
  WIN = 'WIN',
  PUSH = 'PUSH',
  VOID = 'VOID',
}

export type SportsBetTicketStatusType = SportsBetStatus | SportBetUISpecificStatus;

export const SportsBetTicketStatus = {
  ...SportsBetStatus,
  ...SportBetUISpecificStatus,
} as const;

export type BetSlipWarnings = Partial<{
  hasOddsChanges: boolean;
  exceedMaxAllowedStakeAmount: boolean;
}>;

export type BetSlipProps = {
  currency?: Currency;
  actions?: {
    onUpdateAmount?: (payload: {
      betAmount: string;
      inputAmount: string;
    }) => void;
    onValidateBet?: ({
      bet,
      maxBetAmountUSD,
    }: { bet?: BetSlipItem; maxBetAmountUSD?: string }) => void;
    onUpdateMultiBetAmount?: (payload: Partial<MultiBet>) => void;
    onClickViewDetail?: () => void;
    onClickHeader?: () => void;
    onClickLegHeader?: () => void;
    onAcceptNewOdds?: (id: string) => void;
    onAcceptMultiNewOdds?: () => void;
    onRemove?: () => void;
  };
  maxBetEnabled?: boolean;
  sportsBetStatus?: SportsBetTicketStatusType;
  betSlipStatus: BetSlipStatus;
  odds?: ReturnType<typeof getSportBetOdds>;
  publicView?: boolean;
  bets: BetSlipItem[];
  betIds: string[];
  betShortId?: string;
  amount: string;
  oddsFormat: OddsFormat;
  currencyPreference: Currency;
  settlementPayoutOdds?: string;
  settlementPayout?: string;
  cashoutOddsDecimal?: string;
  errors?: BetSlipErrors;
  warnings?: BetSlipWarnings;
  showOddsWarningMessage?: boolean;
  matchStates?: GetSportsMatchStatesQuery['sportsMatchStates'];
  classNames?: Partial<{
    root: string;
    betTicketBody: string;
  }>;
  actionPanelData?: ReturnType<typeof getBetSlipActionPanelData>;
  onMaxBetClick?: (
    data: Pick<PlaceSportsBetInput, 'amount' | 'selectionIds' | 'totalOddsDecimal'>,
  ) => Promise<GetMaxSportsBetAmountUsdQuery | undefined>;
};
