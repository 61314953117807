import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import colors from '../../assets/styles/colors';
import { SvgProps } from '../../assets/svgs/svg.type';
import { INTERCOM_WIDGET_CLASSNAME } from '../../constants/intercom';
import useWindowWidth, { Breakpoint, greaterThanBreakpoint, lessThanBreakpoint } from '../../hooks/interface/useWindowWidth';
import { useMobileNav } from '../../hooks/useMobileNav';
import { toggleLeftSidebar } from '../../redux/slices/browserPreferenceSlice';
import { MobileMenuName } from '../../redux/slices/globalStateSlice';
import { Tooltip } from '../Tooltip';
import { NavigationLinkCounter } from './NavigationLinkCounter';
import styles from './NavigationLink.module.scss';
export interface NavigationLinkProps {
  path?: React.ComponentProps<typeof Link>['href'];
  pathStartWith?: string;
  title?: string;
  icon?: React.FC<SvgProps> | null;
  iconUrl?: string;
  isExpanded: boolean;
  disabled?: boolean;
  counter?: number | React.ReactNode;
  counterVariant?: 'purple' | 'green' | 'text';
  onClick?: () => void;
  className?: string;
  shallow?: boolean;
}
export const NavigationLink = memo(({
  shallow,
  path,
  pathStartWith,
  title,
  icon: Icon,
  iconUrl,
  isExpanded,
  onClick,
  disabled = false,
  counter = 0,
  counterVariant,
  className
}: NavigationLinkProps) => {
  const {
    asPath
  } = useRouter();
  const {
    setActiveMobileNav
  } = useMobileNav();
  const dispatch = useDispatch();
  const {
    width
  } = useWindowWidth();
  const handleClick = () => {
    if (lessThanBreakpoint({
      width,
      breakpoint: Breakpoint.LG
    }) && greaterThanBreakpoint({
      breakpoint: Breakpoint.MD,
      width
    })) {
      dispatch(toggleLeftSidebar({
        isLeftSidebarExpanded: false
      }));
    }
    if (className?.includes(INTERCOM_WIDGET_CLASSNAME)) {
      return;
    }
    onClick?.();
    if (lessThanBreakpoint({
      width,
      breakpoint: Breakpoint.MD
    })) {
      setActiveMobileNav(MobileMenuName.CASINO);
    }
  };
  if (onClick) {
    return <Tooltip content={title} disabled={isExpanded} placement="right">
          <button {...isExpanded ? {
        title
      } : {}} className={`${styles.link} ${counter && !isExpanded ? styles.dot : ''} ${counterVariant === 'text' || counterVariant === 'green' && !isExpanded ? styles.greenDot : ''} ${className || ''}`} onClick={handleClick}>
            <div className={`${styles.navItem}`}>
              {Icon && <Icon color={path === asPath ? colors.primaryViolet : colors.white} />}
              {iconUrl && <img src={iconUrl} alt="icon" />}
              {isExpanded && <span className={styles.navText}>{title}</span>}
              {!!counter && <NavigationLinkCounter isExpanded={isExpanded} counter={counter} counterVariant={counterVariant} />}
            </div>
          </button>
        </Tooltip>;
  }
  if (path) {
    return <>
          <Tooltip content={title} disabled={isExpanded} placement="right">
            {disabled ? <div className={`${styles.navItem} ${styles.disabled}`}>
                {Icon && <Icon />}
                {iconUrl && <img src={iconUrl} alt="icon" />}
                {isExpanded && <span className={styles.navText}>{title}</span>}
              </div> : <Link href={path} className={`${styles.link} ${counter && !isExpanded ? styles.dot : ''} ${path === asPath || pathStartWith && asPath.startsWith(pathStartWith) ? styles.active : ''} ${counterVariant === 'text' || counterVariant === 'green' ? styles.greenDot : ''} ${className || ''}`} onClick={handleClick} shallow={path === asPath || !!shallow}>
                <div className={styles.navItem}>
                  {Icon && <Icon color={path === asPath ? colors.primaryViolet : colors.white} />}
                  {iconUrl && <img src={iconUrl} alt="icon" />}
                  {isExpanded && <span className={styles.navText}>{title}</span>}
                  {!!counter && <NavigationLinkCounter isExpanded={isExpanded} counter={counter} counterVariant={counterVariant} />}
                </div>
              </Link>}
          </Tooltip>
        </>;
  }
  return null;
});