import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/Buttons/Button';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import { ERROR_GENERIC } from '../../../../constants/errors';
import { Currency, SportsBetStatus, useGetSportsBetLazyQuery, useSportBetCashOutMutation } from '../../../../generated/graphql';
import { useLanguagePreference } from '../../../../hooks/useLanguagePreference';
import usePreference from '../../../../hooks/usePreference';
import { useSportsOdds } from '../../../../hooks/useSportsOdds';
import { AlertsEnum, addAlert } from '../../../../redux/slices/alertsSlice';
import { CashOutBetSlips, setCashOutBetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import { calculateCashOut } from '../../../../utils/calculateCashOut';
import { getSportBetOdds } from '../../../../utils/getSportBetOdds';
import { SPORT_BET_ERRORS_LIST } from '../../BetSlipErrorsType';
import styles from './BetSlipCashOutButton.module.scss';
import { getBetSlipsCashoutVariant } from './getBetSlipsCashoutVariant';
interface CashOutButtonProps {
  currency: Currency;
  cashoutOddsDecimal?: string;
  bet: BetSlipItem;
  publicView?: boolean;
  bets: BetSlipItem[];
  betSlipStatus: BetSlipStatus;
  isEventClosed: boolean;
  betShortId: string;
  setBetStatus: Dispatch<SetStateAction<SportsBetStatus | undefined>>;
  cashOutBetSlips: CashOutBetSlips;
  setCashOutInfo: (data: {
    amount: string;
    currency: Currency;
    odds: string;
  }) => void;
}
const tooltipProps = ({
  placement: 'top',
  tooltipPosition: {
    bottom: 40,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 'max-content',
    position: 'absolute'
  }
} as const);
export const BetSlipCashOutButton = ({
  isEventClosed,
  currency,
  bet,
  bets,
  cashoutOddsDecimal,
  betSlipStatus,
  publicView,
  betShortId,
  setBetStatus,
  setCashOutInfo,
  cashOutBetSlips
}: CashOutButtonProps) => {
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const {
    t: errorT
  } = useTranslation('errors');
  const {
    oddsFormat
  } = usePreference();
  const {
    languagePreference
  } = useLanguagePreference();
  const oddsUpdateResult = useSportsOdds({
    odds: cashoutOddsDecimal || '',
    autoHideIndicator: true
  });
  const [sportBetCashOutRequest] = useSportBetCashOutMutation();
  const [updateBetInfo] = useGetSportsBetLazyQuery(betShortId ? {
    variables: {
      id: betShortId,
      withCashoutOdds: true,
      language: languagePreference
    }
  } : undefined);
  const variant = getBetSlipsCashoutVariant({
    bets,
    betSlipStatus,
    isOwner: !publicView
  });
  const isEventClosedAndCashoutEnabled = isEventClosed && variant === 'enabled';
  const cashoutButtonStatus = cashOutBetSlips?.[betShortId]?.status;
  const cashoutAmount = calculateCashOut({
    amount: bet.betAmount,
    cashOutOddsDecimal: cashoutOddsDecimal || '0'
  });
  const cashoutOddsDecimalRef = useRef(cashoutOddsDecimal);
  const handleCashOut = async () => {
    try {
      if (!cashoutOddsDecimal || cashOutBetSlips?.[betShortId]?.status === 'loading' || cashOutBetSlips?.[betShortId]?.status === 'completed') {
        dispatch(addAlert({
          message: errorT(ERROR_GENERIC),
          type: AlertsEnum.Error
        }));
        return;
      }
      dispatch(setCashOutBetSlipStatus({
        id: betShortId,
        status: 'loading'
      }));
      const cashOutResponse = await sportBetCashOutRequest({
        variables: {
          data: {
            sportsBetId: betShortId,
            cashoutOddsDecimal
          },
          language: languagePreference
        }
      });
      if (cashOutResponse.errors) {
        const message = (cashOutResponse.errors[0]?.message as SPORT_BET_ERRORS_LIST);
        if (message && [SPORT_BET_ERRORS_LIST.CASHOUT_INPLAY_ODDS_CHANGED, SPORT_BET_ERRORS_LIST.CASHOUT_ODDS_IS_CHANGED].includes(message)) {
          dispatch(addAlert({
            message: errorT(message),
            type: AlertsEnum.Error
          }));
        } else {
          dispatch(addAlert({
            message: t('txtCashOutUnavailableMsg'),
            type: AlertsEnum.Error
          }));
        }
        await updateBetInfo();
        dispatch(setCashOutBetSlipStatus({
          id: betShortId,
          status: ''
        }));
        return;
      }
      if (cashOutResponse.data?.cashoutSportsBet.id) {
        dispatch(addAlert({
          message: t('txtBetCashedOutNotiTitle'),
          type: AlertsEnum.Success
        }));
        dispatch(setCashOutBetSlipStatus({
          id: betShortId,
          status: 'completed'
        }));
        setBetStatus(SportsBetStatus.CASHED_OUT);
        setCashOutInfo({
          amount: calculateCashOut({
            amount: bet.betAmount,
            cashOutOddsDecimal: cashOutResponse.data?.cashoutSportsBet.settlement?.payoutOddsDecimal
          }),
          currency: cashOutResponse.data?.cashoutSportsBet.currency,
          odds: getSportBetOdds({
            oddsFormat: oddsFormat,
            oddDecimal: BigNumber(cashOutResponse.data?.cashoutSportsBet.settlement?.payoutOddsDecimal ?? 0)
          }).outputText
        });
        return;
      }
    } catch {
      dispatch(addAlert({
        message: errorT(ERROR_GENERIC),
        type: AlertsEnum.Error
      }));
      dispatch(setCashOutBetSlipStatus({
        id: betShortId,
        status: ''
      }));
    } finally {
      if (betShortId) {
        dispatch(setCashOutBetSlipStatus({
          id: betShortId,
          status: ''
        }));
      }
    }
  };
  const onClickCashout = async () => {
    if (!cashoutButtonStatus) {
      dispatch(setCashOutBetSlipStatus({
        id: betShortId,
        status: 'confirm'
      }));
      cashoutOddsDecimalRef.current = cashoutOddsDecimal;
    } else if (cashoutButtonStatus === 'confirm') {
      return handleCashOut();
    }
  };
  useEffect(() => {
    if (cashoutOddsDecimalRef.current !== cashoutOddsDecimal && cashoutButtonStatus === 'confirm') {
      dispatch(setCashOutBetSlipStatus({
        id: betShortId,
        status: ''
      }));
      cashoutOddsDecimalRef.current = cashoutOddsDecimal;
    }
  }, [dispatch, betShortId, cashoutOddsDecimal, cashoutButtonStatus]);
  useEffect(() => {
    if (betSlipStatus) {
      dispatch(setCashOutBetSlipStatus({
        id: betShortId,
        status: ''
      }));
    }
  }, [betShortId, dispatch, betSlipStatus]);
  if (isEventClosedAndCashoutEnabled || publicView && variant !== 'disabled' || !publicView && variant !== 'disabled' && variant !== 'suspended' && variant !== 'enabled' || variant === 'hide' || variant === 'invalid' || variant === 'disabled' || cashoutButtonStatus === 'completed') {
    return null;
  }
  return <div className={clsx({
    [String(styles.cashOutButton)]: variant === 'enabled' || variant === 'suspended' || variant === 'disabled',
    [String(styles.cashOutButtonSuspended)]: variant === 'suspended'
  })}>
      <Button fillIcon={false} loading={cashoutButtonStatus === 'loading'} onClick={onClickCashout} disabled={variant !== 'enabled'} size={'sm'} className={cashoutButtonStatus === 'confirm' || cashoutButtonStatus === 'loading' ? styles.confirmButton : ''}>
        {variant === 'suspended' && t('cashOutSuspended')}
        {variant === 'enabled' && <div className={styles.cashOutField}>
            {cashoutButtonStatus === 'confirm' ? t('confirmCashOut') : t('cashOutFor')}

            {oddsUpdateResult.isOddsChanges && <span className={styles.indicatorIcon}>
                {oddsUpdateResult.isOddsIncrease && <img src="/icons/arrow-increase.svg" alt="increase" />}
                {oddsUpdateResult.isOddsDecrease && <img src="/icons/arrow-decrease.svg" alt="decrease" />}
              </span>}

            <CryptoIcon currency={currency} />
            <FormattedAmount value={cashoutAmount} currency={currency} {...tooltipProps} />
          </div>}
      </Button>
    </div>;
};