import { SportsMarketStatus } from 'generated/graphql';
import { BetSlipErrors, BetSlipErrorsForm } from 'hooks/useSportsBetSlipCheck';

import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';

export const getErrorText = (errors: BetSlipErrors) => {
  if (errors.isInsufficientFunds) {
    return 'txtInsufficientFunds';
  }
  if (errors.isMinStakeNotMet) {
    return 'txtIncreaseYourStake';
  }
  return '';
};

export const getBetSlipErrors = ({
  betSlipErrors,
  betSlipStatus,
}: {
  betSlipErrors: BetSlipErrorsForm;
  betSlipStatus: BetSlipStatus;
}) => {
  const { bets, multiBet, hasTotalAmountInsufficientFunds } = betSlipErrors;
  const shouldShowError =
    betSlipStatus === BetSlipStatus.ADDING_BET || betSlipStatus === BetSlipStatus.CONFIRMING_BET;

  const isInsufficientFundsError =
    (bets.some(item => item.isInsufficientFunds) ||
      multiBet.isInsufficientFunds ||
      hasTotalAmountInsufficientFunds) &&
    shouldShowError;

  const isMinStakeError =
    shouldShowError &&
    !isInsufficientFundsError &&
    (bets.some(item => item.isMinStakeNotMet) || multiBet.isMinStakeNotMet);

  const isEventClosedError = shouldShowError && bets.some(item => item.isEventClosed);

  const isDimmed =
    shouldShowError && !isInsufficientFundsError && (isMinStakeError || isEventClosedError);

  return {
    shouldShowError,
    isInsufficientFundsError,
    isMinStakeError,
    isEventClosedError,
    isDimmed,
  };
};

export const shouldShowOddsWarningMessage = (params: {
  display: boolean;
  oddsChangesIds: string[];
  marketStatus: SportsMarketStatus;
  id: string;
}) => {
  const { display, oddsChangesIds, marketStatus, id } = params;
  return display && oddsChangesIds.includes(id) && marketStatus === SportsMarketStatus.OPEN;
};
