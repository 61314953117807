import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Loader } from '../Loader';
import { ButtonSize, ButtonVariants } from './ButtonVariants';
import { Icon } from './Icon';
import styles from './BaseButton.module.scss';
export type ButtonTypeVariants = 'primary' | 'secondary' | 'tertiary' | 'success' | 'gold' | 'gold-outline';

// only the primary button has a custom loading style
const loadingStyle: Record<ButtonTypeVariants, string> = {
  primary: styles.loading ?? '',
  success: '',
  secondary: '',
  tertiary: styles.tertiaryLoading ?? '',
  gold: '',
  'gold-outline': ''
};
export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
  /**
   * Button size
   */
  size?: ButtonSize;
  /**
   * Button style
   */
  variant?: ButtonTypeVariants;
  /**
   * Button contents
   */
  value?: string | React.ReactNode;
  /**
   * Icon
   */
  icon?: Element | React.ReactNode | string;
  /**
   * Trigger submitting
   */
  /**
   * Add margin top
   */
  hasMargin?: boolean;
  /**
   * use only the dimensions of the icon instead of setting a fixed width and size
   */
  iconNoFixedDimensions?: boolean;

  /** Should icon SVG have filled property when disabled? */
  fillIcon?: boolean;

  /** Should button text be bolder? */
  bold?: boolean;

  /** Custom button text font weight */
  loading?: boolean;
  loadingText?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
  noDisabledStyle?: boolean;
  as?: string;
  isRound?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const Button = ({
  noDisabledStyle,
  size = 'md',
  variant = 'primary',
  value,
  disabled,
  icon = '',
  hasMargin = false,
  fillIcon = true,
  iconNoFixedDimensions = false,
  bold = false,
  loading,
  loadingText,
  children,
  className,
  ...rest
}: ButtonProps) => {
  return <ButtonVariants disabled={disabled || loading} hasMargin={hasMargin} fillIcon={fillIcon} bold={bold} size={size} noDisabledStyle={noDisabledStyle} variant={variant} {...rest} className={`${className || ''} ${loading ? loadingStyle[variant] : ''}`}>
      {loading ? <>
          <Loader invert className={styles.loader} /> {loadingText}
        </> : <>
          {icon && <Icon $noFixedDimensions={iconNoFixedDimensions}>{(icon as ReactNode)}</Icon>}
          {children ?? value}
        </>}
    </ButtonVariants>;
};
export default Button;