import dayjs from 'dayjs';

export interface Countdown {
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function getCountdown(date: Date): Countdown {
  const timeToExpiry = dayjs(date);

  const now = dayjs();

  if (timeToExpiry.isBefore(now))
    return {
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

  const months = Math.floor(timeToExpiry.diff(now, 'months') ?? 0);

  return {
    months: months,
    days: timeToExpiry.diff(now.add(months, 'month'), 'days') ?? 0,
    hours: Math.floor((timeToExpiry.diff(now, 'hours') ?? 0) % 24),
    minutes: Math.floor((timeToExpiry.diff(now, 'minutes') ?? 0) % 60),
    seconds: Math.floor((timeToExpiry.diff(now, 'seconds') ?? 0) % 60),
  };
}
