import dayjs from 'dayjs';
import { Currency, EventState, SportsBetStatus, SportsMarketStatus } from '../../../generated/graphql';
import { useCurrencyInputConversion } from '../../../hooks/useCurrencyInputConversion';
import { useBalances } from '../../../hooks/wallet/useBalances';
import { BetSlipProps, SportsBetTicketStatus } from './BetsGroup.type';
import BigNumber from 'bignumber.js';
import { useConversion } from 'hooks/useConversion';
import { useFiatRate } from 'hooks/useFiatRate';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertsEnum, addAlert } from 'redux/slices/alertsSlice';
import { getSportBetOdds } from 'utils/getSportBetOdds';
import { OddsFormat } from '../../../redux/slices/browserPreferenceSlice';
import { BetSlipStatus } from '../../../redux/slices/sportsBetSlice.type';
import { calculateOddsTotal } from '../../../utils/calculateOddsTotal';
import { calculateEstimatePayout } from '../../../utils/calculateSportsBet';
import { notNullable } from '../../../utils/notNullable';
import { getLiveMatchProgressText } from '../../../utils/sports/getLiveMatchProgressText';
import styles from './BetSlip.module.scss';
import { BetGameEventInfo } from './BetTicket/BetGameEventInfo';
import { BetOddsStakeAndStatus } from './BetTicket/BetOddsStakeAndStatus';
import { BetTitleHeader } from './BetTicket/BetTitleHeader';
import { MultipleBetLegs } from './BetTicket/MultipleBetLegs';
import { getBetViewConfig } from './BetTicket/getBetViewConfig';
import { getPrimarySportsBetStatus } from './getPrimarySportsBetStatus';
export const MAX_NUMBER_OF_BETS = 20;
const DEFAULT_CASHOUT_INFO = {
  amount: '',
  currency: Currency.BTC,
  odds: ''
};
export function BetSlip({
  maxBetEnabled,
  actions,
  sportsBetStatus,
  odds,
  currency,
  bets,
  betIds,
  betShortId,
  betSlipStatus,
  amount,
  oddsFormat,
  currencyPreference,
  settlementPayoutOdds,
  settlementPayout,
  cashoutOddsDecimal,
  publicView,
  onMaxBetClick,
  showOddsWarningMessage = false,
  errors = {
    id: '',
    isInsufficientFunds: false,
    isMinStakeNotMet: false,
    placedBetError: false,
    placedBetErrorIds: []
  },
  warnings = {
    exceedMaxAllowedStakeAmount: false,
    hasOddsChanges: false
  },
  matchStates,
  classNames,
  actionPanelData
}: BetSlipProps) {
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const {
    t: errorT
  } = useTranslation('errors');
  const {
    displayInFiat
  } = usePreference();
  const [betStatus, setBetStatus] = useState<SportsBetStatus>();
  const [triggerMaxBetLoading, setTriggerMaxBetLoading] = useState(false);
  const [highlightLegIndex, setHighlightLegIndex] = useState(-1);
  const {
    bet,
    betsSorted,
    hasMultiSelections
  } = useMemo(() => {
    const betsSorted = [...bets].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()).slice(0, MAX_NUMBER_OF_BETS);
    return {
      betsSorted,
      bet: betsSorted[0],
      hasMultiSelections: betsSorted.length > 1
    };
  }, [bets]);
  const matchState = useMemo(() => matchStates?.find(item => item?.fixtureId === bet?.fixtureId), [bet, matchStates]);
  const [collapses, setCollapses] = useState<boolean[]>(betsSorted.map(_ => [BetSlipStatus.PENDING_BET, BetSlipStatus.RESULTED_BET, BetSlipStatus.BET_VIEW_INFO].includes(betSlipStatus)));
  useEffect(() => {
    setCollapses(data => data.map(_ => [BetSlipStatus.PENDING_BET, BetSlipStatus.RESULTED_BET, BetSlipStatus.BET_VIEW_INFO].includes(betSlipStatus)));
  }, [betSlipStatus]);
  if (!bet) {
    return null;
  }
  const currentCurrency = actions?.onUpdateAmount || actions?.onUpdateMultiBetAmount ? currencyPreference : currency || currencyPreference;
  const {
    inputToCrypto
  } = useCurrencyInputConversion(currentCurrency);
  const currencyMapping = useBalances();
  const fiatRate = useFiatRate();
  const {
    fiatToCrypto
  } = useConversion(currentCurrency);
  const cashOutBetSlips = useSelector((state: AppState) => state.sportsBet.cashOutBetSlips);
  const cashOutInfo = betShortId ? cashOutBetSlips[betShortId] ?? DEFAULT_CASHOUT_INFO : DEFAULT_CASHOUT_INFO;
  const liveMatchProgressText = getLiveMatchProgressText({
    t,
    matchState,
    sports: bet.sport
  });
  const {
    outputDecimal,
    outputText
  } = useMemo(() => calculateOddsTotal({
    bets: betIds.map(id => {
      const betDetail = bets.find(bet => bet.id === id);
      if (betDetail) {
        const {
          totalOddsDecimal,
          ...rest
        } = betDetail;
        return rest;
      }
      return undefined;
    }).filter(notNullable),
    oddsFormat
  }), [bets, oddsFormat, betIds]);
  const estimatePayoutOutput = useMemo(() => settlementPayout || calculateEstimatePayout({
    betAmount: amount,
    totalOdds: odds?.outputDecimal ?? outputDecimal
  }), [amount, odds?.outputDecimal, outputDecimal, settlementPayout]);
  const settmentPayoutOddsOutput = useMemo(() => settlementPayoutOdds && getSportBetOdds({
    oddsFormat: oddsFormat,
    oddDecimal: BigNumber(settlementPayoutOdds)
  }).outputText, [oddsFormat, settlementPayoutOdds]);
  const primarySportsBetStatus = getPrimarySportsBetStatus({
    bets,
    hasMultiSelections,
    sportsBetStatus
  });
  const notAllMatchStaticsReturned = matchStates ? matchStates?.length === bets.length : false;
  const isPayoutMoreThanStakeAmount = BigNumber(estimatePayoutOutput).isGreaterThan(amount);
  const allMatchesClosed = !!matchStates?.every(({
    matchSummary
  }) => matchSummary?.eventState === EventState.CLOSED);
  const isEventClosed = hasMultiSelections ? notAllMatchStaticsReturned && allMatchesClosed : matchState?.matchSummary?.eventState === EventState.CLOSED;
  const isLive = matchState?.matchSummary?.eventState === EventState.LIVE;
  const isPreMatch = matchState?.matchSummary?.eventState === EventState.PREMATCH || dayjs(bet.startTime).isAfter(dayjs());
  const allBetsResulted = useMemo(() => betsSorted.every(bet => getBetViewConfig({
    sportsBetStatus: bet.sportsBetStatus,
    isPreMatch
  }).isResulted), [betsSorted, isPreMatch]);
  const shouldShowOddsWarningMessage = !isEventClosed && showOddsWarningMessage;
  const shouldShowLiveBadge = isLive && bet?.marketStatus !== SportsMarketStatus.RESULTED && bet?.sportsBetStatus !== SportsBetTicketStatus.VOIDED;
  const {
    shouldShowReceipt
  } = getBetViewConfig({
    betSlipStatus,
    sportsBetStatus
  });
  const onChangeBetAmount = useCallback((cryptoValue: string, rawValue: string) => {
    const onChangeValue = inputToCrypto(rawValue, currencyMapping[currentCurrency]);
    if (hasMultiSelections) {
      actions?.onUpdateMultiBetAmount?.({
        inputAmount: cryptoValue,
        betAmount: onChangeValue
      });
    } else {
      actions?.onUpdateAmount?.({
        inputAmount: cryptoValue,
        betAmount: onChangeValue
      });
    }
  }, [actions, inputToCrypto, hasMultiSelections, currentCurrency, currencyMapping]);
  const onMaxBetClickWithAmountUpdate = async () => {
    if (!onMaxBetClick || !onChangeBetAmount) {
      return;
    }
    setTriggerMaxBetLoading(true);
    try {
      const amount = await onMaxBetClick({
        selectionIds: bets.map(bet => bet.id),
        amount: bet.betAmount,
        totalOddsDecimal: calculateOddsTotal({
          bets,
          oddsFormat: OddsFormat.Decimal
        }).outputNumber
      });
      if (amount) {
        const maxBetAmountFiat = BigNumber(amount.maxBetAmountUSD).multipliedBy(fiatRate);
        const maxBetAmountCrypto = fiatToCrypto(maxBetAmountFiat.toString());
        const maxBetAmountByCurrency = displayInFiat ? maxBetAmountFiat : maxBetAmountCrypto;
        onChangeBetAmount(maxBetAmountCrypto, maxBetAmountByCurrency.toString());
        actions?.onValidateBet?.({
          ...(hasMultiSelections ? {} : {
            bet
          }),
          maxBetAmountUSD: amount.maxBetAmountUSD
        });
      }
    } catch (e) {
      dispatch(addAlert({
        type: AlertsEnum.Error,
        message: errorT((e as Error).message)
      }));
    }
    setTriggerMaxBetLoading(false);
  };
  return <div className={`${styles.betSlipWrapper} ${classNames?.root || ''}`}>
      <div className={styles.wrapper}>
        <BetTitleHeader betIds={betIds} cashOutInfo={cashOutInfo} betSlipStatus={betSlipStatus} bet={bet} sportsBetStatus={primarySportsBetStatus} hasMultiSelections={hasMultiSelections} errors={errors} showLiveBadge={shouldShowLiveBadge} warnings={warnings} showOddsWarningMessage={shouldShowOddsWarningMessage} isPayoutMoreThanStakeAmount={isPayoutMoreThanStakeAmount} betStatus={betStatus} actions={actions} />

        <div className={`${styles.betInfoLegsWrapper} ${shouldShowReceipt ? styles.shouldShowReceipt : ''}`}>
          <BetGameEventInfo onMaxBetClickWithAmountUpdate={onMaxBetClickWithAmountUpdate} maxBetEnabled={maxBetEnabled} maxBetLoading={triggerMaxBetLoading} actionPanelData={actionPanelData} liveMatchProgressText={liveMatchProgressText} setAnimateLegIndex={setHighlightLegIndex} allBetsResulted={allBetsResulted} setCollapse={setCollapses} collapses={collapses} betIds={betIds} odds={odds} classNames={classNames} betSlipStatus={betSlipStatus} oddsOutputText={outputText} hasMultiSelections={hasMultiSelections} bet={bet} sportsBetStatus={primarySportsBetStatus} errors={errors} onChangeBetAmount={onChangeBetAmount} estimatePayoutOutput={estimatePayoutOutput} amount={amount} actions={actions} currency={currentCurrency} bets={bets} isPreMatch={isPreMatch} isLive={isLive} isEventClosed={isEventClosed} matchState={matchState} showOddsWarningMessage={shouldShowOddsWarningMessage} matchStates={matchStates} />

          {hasMultiSelections && <MultipleBetLegs shouldShowReceipt={shouldShowReceipt} actionPanelData={actionPanelData} highlightLegIndex={highlightLegIndex} setCollapse={setCollapses} collapses={collapses} bets={betsSorted} errors={errors} betSlipStatus={betSlipStatus} betIds={betIds} currency={currentCurrency} actions={actions} showOddsWarningMessage={showOddsWarningMessage} matchStates={matchStates} />}
        </div>

        {shouldShowReceipt && <div className={styles.footerContainer}>
            <img className={styles.logo} src="/icons/shuffle-com-logo.svg" alt="shuffle" />
          </div>}

        <BetOddsStakeAndStatus bet={bet} cashOutInfo={cashOutInfo} cashOutBetSlips={cashOutBetSlips} betStatus={betStatus} setBetStatus={setBetStatus} bets={bets} sportsBetStatus={primarySportsBetStatus} odds={odds} betShortId={betShortId} publicView={publicView} betSlipStatus={betSlipStatus} currency={currentCurrency} amount={amount} estimatePayoutOutput={estimatePayoutOutput} oddsOutputText={outputText} settmentPayoutOddsOutput={settmentPayoutOddsOutput} cashoutOddsDecimal={cashoutOddsDecimal} isEventClosed={isEventClosed} isPayoutMoreThanStakeAmount={isPayoutMoreThanStakeAmount} />

        {shouldShowReceipt && <div className={styles.footer} />}
      </div>
    </div>;
}