import { OnDataOptions } from '@apollo/client';
import dayjs from 'dayjs';
import {
  EventState,
  Sports,
  SportsFixtureCounterUpdatedSubscription,
  SportsMarketStatus,
  SportsMatchSummaryUpdatedSubscription,
  useSportsFixtureCounterUpdatedSubscription,
} from 'generated/graphql';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomCompareEffect } from 'react-use';
import { setBetSlip, updateBetSlipOddsChangesIds } from 'redux/slices/sportsBetSlice';
import { clientWsSports } from '../apollo/clients';
import store from '../redux/store';
import { getBetIndexes } from '../utils/getBetIndexes';
import { handleBetSlipsUpdate } from '../utils/handleBetSlipUpdate';
import { ENABLED_BET_SLIP_STATUS_OPTIONS, sportsSubscription } from '../utils/sportsSubscription';
import { SportsFixtureContentStoreType, SportsSelection } from './defaultExternalStore.type';
import { useStore } from './useStore';

export const {
  triggerSportsSubscriptions,
  updateSportsUpdatedCallback,
  updateSportsUpdatedBetSlipsRefetchCallback,
  updateSportsUpdatedBetSlipRefetchCallback,
  updateSportsMatchSummaryUpdatedCallback,
  getSubscribedFixtureIds,
} = sportsSubscription();

export const useSportsSubscriptions = () => {
  const { setState } = useStore();
  const dispatch = useDispatch();
  const betSlips = useSelector((state: AppState) => state.sportsBet.betSlips);

  useSportsFixtureCounterUpdatedSubscription({
    onData: useCallback(
      ({ data: { data } }: OnDataOptions<SportsFixtureCounterUpdatedSubscription>) => {
        if (!data?.sportsFixturesCounterUpdated) {
          return;
        }

        const liveSports: Partial<Record<Sports, number>> = {};
        const upComingSports: Partial<Record<Sports, number>> = {};

        for (const count of data.sportsFixturesCounterUpdated.live.sportsCount) {
          liveSports[count.sports] = count.fixtureIds.length;
        }

        for (const count of data.sportsFixturesCounterUpdated.upcoming.sportsCount) {
          upComingSports[count.sports] = count.fixtureIds.length;
        }

        setState('sportsCounter', () => ({
          liveTotal: data.sportsFixturesCounterUpdated.live.total,
          upcomingTotal: data.sportsFixturesCounterUpdated.upcoming.total,
          live: liveSports,
          upcoming: upComingSports,
        }));
      },
      [setState],
    ),
    client: clientWsSports,
  });

  useEffect(() => {
    updateSportsUpdatedCallback(({ data: dataChanges }) => {
      const markets = dataChanges?.sportsUpdated.markets;
      const fixtureId = dataChanges?.sportsUpdated.fixtureId;

      if (!markets || !fixtureId) {
        return;
      }

      const { browserPreference: preferenceSliceState, sportsBet: sportsSliceState } =
        store.getState();

      let updatedBetSlips = sportsSliceState.betSlips;
      const betSlipOddChangesIdBatch = new Set<string>();

      const shouldUpdateBetOdds =
        ENABLED_BET_SLIP_STATUS_OPTIONS.includes(sportsSliceState.betSlipStatus) &&
        updatedBetSlips.length;

      setState('sportsFixture', sportsFixtureState => {
        const sportsFixtureCopy = { ...sportsFixtureState };
        const sportsFixture: SportsFixtureContentStoreType = sportsFixtureCopy[fixtureId]
          ? { ...sportsFixtureCopy[fixtureId]! }
          : {
              market: {},
              status: undefined,
              matchSummary:
                {} as SportsMatchSummaryUpdatedSubscription['sportsMatchSummaryUpdated'],
            };

        for (const market of markets) {
          const { id: marketId, disabled, inPlay, status } = market;
          const existingMarket = sportsFixture.market[marketId];

          sportsFixture.market[marketId] = {
            ...(existingMarket
              ? existingMarket
              : {
                  selections: {},
                }),
            inPlay,
            status: disabled ? SportsMarketStatus.CLOSED : status,
          };

          if (shouldUpdateBetOdds) {
            updatedBetSlips = handleBetSlipsUpdate({
              betSlips: updatedBetSlips,
              market,
              acceptOddsChange: preferenceSliceState.acceptOddsChange,
              updateChangeOddsIds: selectionId => {
                betSlipOddChangesIdBatch.add(selectionId);
              },
            });
          }

          if (Array.isArray(market.selections)) {
            for (const selection of market.selections) {
              (sportsFixture.market[marketId] ??= {
                inPlay,
                status,
                selections: {},
              }).selections[selection.id] = {
                oddsNumerator: selection.oddsNumerator,
                oddsDenominator: selection.oddsDenominator,
                status: selection.status,
                probability: selection.probability,
              } as SportsSelection;
            }
          }
        }

        sportsFixtureCopy[fixtureId] = { ...sportsFixture };

        if (shouldUpdateBetOdds) {
          dispatch(setBetSlip({ betSlips: updatedBetSlips }));

          if (betSlipOddChangesIdBatch.size) {
            dispatch(updateBetSlipOddsChangesIds({ ids: Array.from(betSlipOddChangesIdBatch) }));
          }
        }

        return sportsFixtureCopy;
      });
    });

    updateSportsMatchSummaryUpdatedCallback(({ data }) => {
      const matchSummaryUpdates = data?.sportsMatchSummaryUpdated;

      if (!matchSummaryUpdates) {
        return;
      }

      const betSlips = store.getState().sportsBet.betSlips;
      const eventState = matchSummaryUpdates?.eventState;
      const isEventSuspended = eventState === EventState.SUSPENDED;
      const isEventClosed = eventState === EventState.CLOSED;

      // update bet slip data when has event closed should change market status to closed
      if (betSlips.length && (isEventClosed || isEventSuspended)) {
        const index = getBetIndexes({
          betSlips,
          fixtureId: matchSummaryUpdates?.fixtureId,
        });

        if (index >= 0) {
          const bets = [...betSlips];
          const betSlip = bets[index];

          if (betSlip) {
            bets[index] = {
              ...betSlip,
              marketStatus: SportsMarketStatus.CLOSED,
            };

            dispatch(setBetSlip({ betSlips: bets }));
          }
        }
      }

      setState('sportsFixture', sportsFixtureData => {
        const { __typename, ...matchSummary } = matchSummaryUpdates;
        const sportsFixtureCopy = { ...sportsFixtureData };

        const existingMatchSummary = sportsFixtureCopy[matchSummaryUpdates.fixtureId];

        if (
          !existingMatchSummary?.matchSummary?.providerMessageTimestamp ||
          (existingMatchSummary?.matchSummary?.providerMessageTimestamp &&
            dayjs(existingMatchSummary.matchSummary.providerMessageTimestamp).isBefore(
              matchSummaryUpdates.providerMessageTimestamp,
            ))
        ) {
          const fixtureData = sportsFixtureCopy[matchSummaryUpdates.fixtureId];

          if (fixtureData) {
            sportsFixtureCopy[matchSummaryUpdates.fixtureId] = {
              ...fixtureData,
              matchSummary: matchSummary,
            };
          }
        }
        return sportsFixtureCopy;
      });
    });
  }, [dispatch, setState]);

  useCustomCompareEffect(
    () => {
      triggerSportsSubscriptions({
        betSlipsFixtureIds: betSlips
          .map(betSlip => ({
            id: betSlip.marketStatus !== SportsMarketStatus.RESULTED ? betSlip.fixtureId : '',
          }))
          .filter(data => data.id),
      });
    },
    [betSlips],
    ([current], [previous]) => {
      const currentBetIds = current?.map(data => data.id);
      const previousBetIds = previous?.map(data => data.id);
      return isEqual(currentBetIds, previousBetIds);
    },
  );
};
