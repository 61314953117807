import { FetchResult } from '@apollo/client';
import { xor } from 'lodash';
import debounce from 'lodash/debounce';
import { clientWsSports } from '../apollo/clients';
import { DEV_TOOL_IDS } from '../components/SportsDevToolContent';
import {
  SportsMatchSummaryUpdatedDocument,
  SportsMatchSummaryUpdatedSubscription,
  SportsUpdatedDocument,
  SportsUpdatedSubscription,
} from '../generated/graphql';
import { BetSlipStatus } from '../redux/slices/sportsBetSlice.type';
import store from '../redux/store';
import {
  FixtureIdsOnScreen,
  FixtureOnScreen,
  PrepareFixtureIdsForSubscriptionProps,
} from './sportsSubscription.type';
import { sportsSubscriptionMapFixtureIds } from './sportsSubscriptionMapFixtureIds';

const subscriptionDefault = {
  unsubscribe: () => {},
};

export const ENABLED_BET_SLIP_STATUS_OPTIONS = [
  BetSlipStatus.ADDING_BET,
  BetSlipStatus.CONFIRMING_BET,
  BetSlipStatus.BET_PLACING,
  BetSlipStatus.BET_PLACED,
];

const DEBOUNCE_DURATION = 100;

export function sportsSubscription() {
  let subscribeSportsUpdatedCallback = (_: FetchResult<SportsUpdatedSubscription>) => {};
  let subscribeSportsMatchSummaryUpdatedCallback = (
    _: FetchResult<SportsMatchSummaryUpdatedSubscription>,
  ) => {};
  let subscribeSportsUpdatedBetSlipsRefetchCallback = () => {};
  let subscribeSportsUpdatedBetSlipRefetchCallback = () => {};

  let selectionsFixtureIdsOnScreen: FixtureOnScreen[] = [];
  let betSlipsFixtureIdsOnScreen: FixtureOnScreen[] = [];
  let myBetSlipsFixtureIdsOnScreen: FixtureOnScreen[] = [];
  let modalBetSlipsFixtureIdsOnScreen: FixtureOnScreen[] = [];

  let fixtureIdsOnScreen: FixtureOnScreen[] = [];
  let fixtureIdsFlatOnScreen: FixtureIdsOnScreen[] = [];

  let sportsUpdatedSubscription = subscriptionDefault;
  let sportsMatchSummaryUpdatedSubscription = subscriptionDefault;

  const setupSportsUpdatedSubscription = (variables: {
    fixtureIds: string[];
  }) => {
    return clientWsSports
      .subscribe({
        variables,
        query: SportsUpdatedDocument,
      })
      .subscribe(data => {
        if (data) {
          subscribeSportsUpdatedCallback(data);

          if (data?.data?.sportsUpdated?.markets?.length) {
            subscribeSportsUpdatedBetSlipsRefetchCallback();
            subscribeSportsUpdatedBetSlipRefetchCallback();
          }
        }
      });
  };

  const setupSportsMatchSummaryUpdatedSubscription = (variables: {
    fixtureIds: string[];
  }) =>
    clientWsSports
      .subscribe({
        variables,
        query: SportsMatchSummaryUpdatedDocument,
      })
      .subscribe(data => {
        if (data) {
          subscribeSportsMatchSummaryUpdatedCallback(data);
        }
      });

  const prepareFixtureIdsForSubscription = ({
    selectionsFixtureIds,
    betSlipsFixtureIds,
    myBetSlipsFixtureIds,
    modalBetSlipsFixtureIds,
  }: PrepareFixtureIdsForSubscriptionProps) => {
    const sportsBetStore = store.getState().sportsBet;
    const devToolEnabled = store.getState().globalState.sportsDevtool.enabled;

    betSlipsFixtureIdsOnScreen = betSlipsFixtureIds ?? betSlipsFixtureIdsOnScreen;
    selectionsFixtureIdsOnScreen = selectionsFixtureIds ?? selectionsFixtureIdsOnScreen;
    myBetSlipsFixtureIdsOnScreen = myBetSlipsFixtureIds ?? myBetSlipsFixtureIdsOnScreen;
    modalBetSlipsFixtureIdsOnScreen = modalBetSlipsFixtureIds ?? modalBetSlipsFixtureIdsOnScreen;

    fixtureIdsOnScreen = sportsSubscriptionMapFixtureIds({
      betSlipsOnScreen: betSlipsFixtureIdsOnScreen,
      selectionsOnScreen: selectionsFixtureIdsOnScreen,
      modalBetSlipsOnScreen: modalBetSlipsFixtureIdsOnScreen,
      myBetSlipsOnScreen: myBetSlipsFixtureIdsOnScreen,
    });

    const updatedFixtureIdsFlatOnScreen = fixtureIdsOnScreen.map(data => data.id);

    const shouldResetSubscription = !!xor(fixtureIdsFlatOnScreen, updatedFixtureIdsFlatOnScreen)
      .length;

    fixtureIdsFlatOnScreen = updatedFixtureIdsFlatOnScreen;

    return {
      betSlipsFixtureIdsOnScreen,
      selectionsFixtureIdsOnScreen,
      fixtureIdsFlatOnScreen,
      shouldResetSubscription,
      sportsUpdatedSubscriptionIds: sportsSubscriptionMapFixtureIds({
        betSlipsOnScreen: sportsBetStore.betSlips.map(betSlip => ({
          id: betSlip.fixtureId,
        })),
        selectionsOnScreen: selectionsFixtureIdsOnScreen,
        myBetSlipsOnScreen: myBetSlipsFixtureIdsOnScreen,
        betSlipStatus: sportsBetStore.betSlipStatus,
        modalBetSlipsOnScreen: modalBetSlipsFixtureIdsOnScreen,
      }).map(data => data.id),
      devToolEnabled,
    };
  };

  const getSubscribedFixtureIds = () => {
    return {
      betSlipsFixtureIdsOnScreen: betSlipsFixtureIdsOnScreen.map(data => data.id),
      myBetSlipsFixtureIdsOnScreen: myBetSlipsFixtureIdsOnScreen.map(data => data.id),
      selectionsFixtureIdsOnScreen: selectionsFixtureIdsOnScreen.map(data => data.id),
    };
  };

  const triggerSportsSubscriptions = (props: PrepareFixtureIdsForSubscriptionProps) => {
    try {
      const {
        devToolEnabled,
        fixtureIdsFlatOnScreen,
        sportsUpdatedSubscriptionIds,
        shouldResetSubscription,
      } = prepareFixtureIdsForSubscription(props);

      if (!shouldResetSubscription) {
        return;
      }

      sportsUpdatedSubscription.unsubscribe();
      sportsMatchSummaryUpdatedSubscription.unsubscribe();

      if (devToolEnabled) {
        // The following are for debug, will remove once optimisation is completed
        const content = document.getElementById(DEV_TOOL_IDS['sub-ids']);

        if (content) {
          content.innerText = JSON.stringify(getSubscribedFixtureIds(), null, 2);
        }
      }

      if (fixtureIdsOnScreen.length) {
        sportsUpdatedSubscription = setupSportsUpdatedSubscription({
          fixtureIds: sportsUpdatedSubscriptionIds,
        });

        sportsMatchSummaryUpdatedSubscription = setupSportsMatchSummaryUpdatedSubscription({
          fixtureIds: fixtureIdsFlatOnScreen,
        });
      }
    } catch (e) {
      console.error(`Failed to establish subscription ${(e as Error).message || ''}`);
    }
  };

  return {
    triggerSportsSubscriptions,
    updateSportsUpdatedCallback: (callback: typeof subscribeSportsUpdatedCallback) => {
      subscribeSportsUpdatedCallback = callback;
    },
    updateSportsUpdatedBetSlipRefetchCallback: (
      callback: typeof subscribeSportsUpdatedBetSlipRefetchCallback,
    ) => {
      subscribeSportsUpdatedBetSlipRefetchCallback = debounce(callback, DEBOUNCE_DURATION);
    },
    updateSportsUpdatedBetSlipsRefetchCallback: (
      callback: typeof subscribeSportsUpdatedBetSlipsRefetchCallback,
    ) => {
      subscribeSportsUpdatedBetSlipsRefetchCallback = debounce(callback, DEBOUNCE_DURATION);
    },
    updateSportsMatchSummaryUpdatedCallback: (
      callback: typeof subscribeSportsMatchSummaryUpdatedCallback,
    ) => {
      subscribeSportsMatchSummaryUpdatedCallback = callback;
    },
    getSubscribedFixtureIds,
  };
}
