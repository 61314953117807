import colors from '../../assets/styles/colors';
import { WalletModalTabType } from '../../constants/modal';
import { Currency, Language } from '../../generated/graphql';
import { ModalUrl, QueryTypes } from '../Modal/GlobalModal.type';

export const MAX_TOKEN_AMOUNT = 1_000_000_000;
export const MAX_TOKEN_AMOUNT_STRING = '1,000,000,000';
export const TOKEN_UNLOCK_RATE_AT = '50.00';
export const TOKEN_UNLOCK_PRICE = '1.00';

export const TOKEN_AIRDROP_START_DATE = '2024-03-14T06:30:00Z';
export const SHFL_TOKEN_PRICE_DECIMAL = 4;
export const SHFL_TOKEN_DECIMAL = 0;
export const PERCENTAGE_DECIMAL = 2;

export const DOC_LINKS: Record<string, string> = {
  [Language.JA]: 'shfl-jp.shuffle.com',
  [Language.EN]: 'shfl.shuffle.com',
  [Language.ZH]: 'shfl-zh.shuffle.com',
};

export const BUY_LINK = `?${QueryTypes['md-tab']}=${WalletModalTabType.BUY_CRYPTO}&${QueryTypes.modal}=${ModalUrl.WALLET}&${QueryTypes.currency}=${Currency.SHFL}`;

export const CHART_CONFIG = {
  tooltipStyle: {
    contentStyle: {
      backgroundColor: colors.gray700,
      border: `1px solid ${colors.gray500}`,
      borderRadius: '0.5rem',
      color: 'white',
      padding: '0.75rem',
      fontSize: '0.875rem',
    },
    itemStyle: {
      color: 'white',
      padding: 0,
    },
    labelStyle: {
      color: 'white',
    },
  },
  graphProps: {
    margin: {
      bottom: -10,
      right: -15,
    },
    height: 200,
    axisStyle: {
      fontSize: '0.75rem',
      fill: colors.gray400,
      fontWeight: 400,
    },
    radius: [10, 10, 0, 0] as [number, number, number, number],
    aspect: 16 / 5,
  } as const,
};
