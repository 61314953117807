import React, { ButtonHTMLAttributes } from 'react';
import Button from './Button';
import { Icon } from './Icon';
import { PrimaryButton } from './PrimaryButton';
interface ButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: 'primary' | 'secondary' | 'tertiary';
  hasMargin?: boolean;
  children: React.ReactNode;
}
export const ButtonWrapper = ({
  color = 'primary',
  hasMargin = false,
  children,
  ...rest
}: ButtonWrapperProps) => {
  return <>
      {color === 'primary' && <PrimaryButton hasMargin={hasMargin} isRound size="sm" {...rest}>
          {children}
        </PrimaryButton>}
      {color === 'secondary' && <Button variant={'secondary'} hasMargin={hasMargin} isRound size="sm" {...rest}>
          {children}
        </Button>}
      {color === 'tertiary' && <Button variant={'tertiary'} hasMargin={hasMargin} isRound size="sm" {...rest}>
          {children}
        </Button>}
    </>;
};
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button style
   */
  color?: 'primary' | 'secondary' | 'tertiary';
  /**
   * Icon
   */
  icon: React.ReactNode | string;
  /**
   * Add margin top
   */
  hasMargin?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const RoundButton = ({
  color = 'primary',
  icon = '',
  hasMargin = false,
  ...rest
}: ButtonProps) => {
  return <ButtonWrapper hasMargin={hasMargin} {...rest} color={color}>
      <Icon>{icon}</Icon>
    </ButtonWrapper>;
};
export default RoundButton;