import { useTranslation } from 'next-i18next';
import { INTERCOM_WIDGET_CLASSNAME } from '../../constants/intercom';
import { SportsBetStatus, useGetMySportsBetsTotalQuery, useGetRaceLeaderBoardV2Query } from '../../generated/graphql';
import { useIsLoggedIn } from '../../hooks/auth/useIsLoggedIn';
import { NavList } from './NavList';
import { NavigationLink } from './NavigationLink';
import { NavigationLotteryAndWeekly } from './NavigationLotteryAndWeekly';
import { NavigationProfile } from './NavigationProfile';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styles from './HomeTab.module.scss';
type Props = {
  isExpanded: boolean;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
export function HomeTab({
  isExpanded,
  raceLeaderBoardResult
}: Props) {
  const isLoggedIn = useIsLoggedIn();
  const {
    t
  } = useTranslation();
  const sportsBetMaintenance = useSelector((state: AppState) => state.globalState.sportsBetMaintenanceShow);
  const mySportsBet = useGetMySportsBetsTotalQuery({
    variables: {
      statuses: [SportsBetStatus.PENDING, SportsBetStatus.PARTIAL_PENDING]
    },
    fetchPolicy: 'cache-and-network',
    skip: sportsBetMaintenance
  });
  const {
    query
  } = useRouter();
  const preview = query.cms === 'preview';
  const blogHomeUrl = `/blog${preview ? '?cms=preview' : ''}`;
  return <>
      <NavList isExpanded={isExpanded}>
        <NavigationLink path="/" title={t('navHome')} iconUrl="/icons/home.svg" isExpanded={isExpanded} />

        {!sportsBetMaintenance && <NavigationLink path={{
        pathname: '/sports/',
        query: {
          section: 'my-bets'
        }
      }} title={t('tabMyBets')} iconUrl="/icons/sports-bet-slip.svg" isExpanded={isExpanded} counter={mySportsBet.data?.sportsBets.totalCount ?? 0} />}

        <NavigationLink path="/promotion" title={t('footer.promotions.text')} iconUrl="/icons/promotion.svg" isExpanded={isExpanded} disabled />
      </NavList>

      <NavList isExpanded={isExpanded}>
        <NavigationLotteryAndWeekly raceLeaderBoardResult={raceLeaderBoardResult} isExpanded={isExpanded} className={`${isLoggedIn ? styles.weeklyLinks : ''} ${isExpanded ? styles.isExpanded : ''}`} />

        <NavigationProfile isExpanded={isExpanded} />

        <NavigationLink path="/vip-program" title="VIP" iconUrl="/icons/vip.svg" isExpanded={isExpanded} />
        <NavigationLink path={blogHomeUrl} title={t('Blog')} iconUrl="/icons/blog.svg" isExpanded={isExpanded} />
        <NavigationLink path={isLoggedIn ? '/affiliate/overview' : '/affiliate'} title={t('affiliate')} iconUrl="/icons/affiliate.svg" isExpanded={isExpanded} />
        <NavigationLink path="/affiliate" title={t('footer.liveChat.text')} iconUrl="/icons/live-support.svg" isExpanded={isExpanded} onClick={() => {}} className={INTERCOM_WIDGET_CLASSNAME} />
      </NavList>
    </>;
}