import { useCallback, useEffect, useRef } from 'react';

import { Popup } from './usePopup.type';
import { useStore } from './useStore';

export const MAX_POPUP_SIZE = 100;
const BASE_INDEX = 5;

export function usePopup({ slug }: { slug: string }) {
  const { setState } = useStore();
  const currentSlug = useRef('');

  const addPopup = useCallback(
    ({
      slug,
      type,
      videoStreamSource,
      error,
      title,
      timeTitle,
      footerText,
      footerLink,
      widgetUrl,
    }: Popup) => {
      setState('popups', popupsState => {
        const popupValues = Object.values(popupsState);
        const zIndexes = popupValues.map(popup => popup.zIndex).filter(Boolean) as number[];

        const popupsStateCopy = { ...popupsState };
        popupsStateCopy[slug] = {
          open: true,
          zIndex: popupValues.length && zIndexes.length ? Math.max(...zIndexes) + 1 : BASE_INDEX,
          type,
          slug,
          title,
          footerText,
          footerLink,
          timeTitle,
          widgetUrl,
          videoStreamSource,
          error,
        };

        return popupsStateCopy;
      });

      currentSlug.current = slug;
    },
    [setState],
  );

  const removePopup = useCallback(
    (slug: string) => {
      setState('popups', popupsState => {
        const popupsCopy = { ...popupsState };
        delete popupsCopy[slug];
        return popupsCopy;
      });
    },
    [setState],
  );

  const updatePopup = useCallback(
    (popupConfig: Popup) => {
      setState('popups', popupsState => {
        const popupsCopy = { ...popupsState };
        if (popupsState[popupConfig.slug]) {
          popupsCopy[popupConfig.slug] = {
            ...popupsCopy[popupConfig.slug],
            ...popupConfig,
          };
        } else {
          popupsCopy[popupConfig.slug] = popupConfig;
        }

        return popupsCopy;
      });
    },
    [setState],
  );

  const updatePopupsZIndex = (slug: string) => {
    setState('popups', popupsState => {
      const popupsCopy = { ...popupsState };
      let foundIndex = 0;
      let i = 0;

      const popupsKeys = Object.keys(popupsState);

      for (const key of popupsKeys) {
        if (slug === key) {
          foundIndex = i;
          break;
        }
        i++;
      }

      i = 0;

      const popupEntries = Object.entries(popupsCopy);
      for (const [key, value] of popupEntries) {
        if (foundIndex === i) {
          const zIndex = BASE_INDEX + popupEntries.length;

          if (popupsState[key]?.zIndex === zIndex) {
            return popupsCopy;
          }

          popupsCopy[key] = { ...value, zIndex };
        } else {
          popupsCopy[key] = {
            ...value,
            zIndex: BASE_INDEX,
          };
        }
        i++;
      }

      return popupsCopy;
    });
  };

  const { state: currentPopupSlugOpen } = useStore(
    state => !!state.popups[currentSlug.current]?.open,
  );

  useEffect(() => {
    if (!slug && currentSlug.current && !currentPopupSlugOpen) {
      removePopup(currentSlug.current);
      currentSlug.current = '';
    }
  }, [slug, currentPopupSlugOpen, removePopup]);

  return {
    addPopup,
    updatePopup,
    updatePopupsZIndex,
    removePopup,
  };
}
