import { ChevronDownIcon } from 'components/ChevronDownIcon';
import { Breakpoint, lessThanBreakpoint } from 'hooks/interface/useWindowWidth';
import { ReactNode, useMemo, useRef } from 'react';
import { HiddenSelect, Key, useButton, useSelect } from 'react-aria';
import { Item, useSelectState } from 'react-stately';
import { useStore } from '../../hooks/useStore';
import { FormControlWrapper } from './FormControlWrapper';
import { Label } from './Label';
import { LabelBlock } from './LabelBlock';
import { ListBox } from './ListBox';
import { Popover } from './Popover';
import styles from './SelectInput.module.scss';
export interface SelectOption<T extends string> {
  label: ReactNode;
  value: T;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
}
export interface SelectInputProps<T extends string> {
  onChange: (val: T) => void;
  options: SelectOption<T>[];

  // aria-label for accessibility
  name: string;
  label?: string;
  disabled?: boolean;
  value?: T;
  classNames?: {
    root?: string;
    icon?: string;
  };

  // A custom component to display in the menu control
  displayOption?: ReactNode;
  testId?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  disabledKeys?: T[];
  placeholder?: string;
  readOnly?: boolean;
  defaultView?: 'desktop' | 'mobile';
  mobileIcon?: ReactNode;
  mobileBreakPoint?: Breakpoint;
}
export function Select<T extends string>({
  label,
  options,
  disabled,
  disabledKeys,
  value,
  classNames,
  name,
  displayOption,
  testId,
  errorMessage,
  isInvalid,
  placeholder,
  readOnly,
  defaultView = 'desktop',
  mobileIcon,
  onChange,
  mobileBreakPoint = Breakpoint.XS
}: SelectInputProps<T>) {
  const ref = useRef<HTMLButtonElement>(null);
  const items = useMemo(() => {
    return options.map(option => <Item key={option.value} textValue={option.value || 'empty'}>
        {!!option.icon && <span className={styles.selectOptionIcon}>{option.icon}</span>}
        <span className={styles.text}>{option.label}</span>
        {!!option.rightIcon && <span className={styles.rightIcon}>{option.rightIcon}</span>}
      </Item>);
  }, [options]);
  const selectStateProps = useMemo(() => ({
    label,
    disabledKeys,
    'aria-label': name,
    isDisabled: disabled || readOnly,
    selectedKey: value || options[0]?.value,
    onSelectionChange: (key: Key) => onChange((key as T)),
    children: items,
    placeholder,
    errorMessage,
    isInvalid,
    readOnly
  }), [onChange, label, disabledKeys, name, disabled, readOnly, value, options, items, placeholder, errorMessage, isInvalid]);
  const state = useSelectState(selectStateProps);
  const {
    triggerProps,
    valueProps,
    menuProps
  } = useSelect(selectStateProps, state, ref);
  const {
    buttonProps
  } = useButton(triggerProps, ref);
  const {
    state: contentWidth
  } = useStore(state => state.contentWidth);
  const isMobile = lessThanBreakpoint({
    width: contentWidth,
    breakpoint: mobileBreakPoint
  });
  const showMobileButton = (isMobile || defaultView === 'mobile') && !!mobileIcon;
  return <FormControlWrapper>
      {label && <LabelBlock>{label && <Label>{label}</Label>}</LabelBlock>}

      <HiddenSelect isDisabled={disabled} state={state} triggerRef={ref} label={label} name={name} />

      {showMobileButton ? <button {...buttonProps} disabled={disabled} className={styles.mobileButton} ref={ref} data-testid={testId}>
          {mobileIcon}
        </button> : <button {...buttonProps} ref={ref} disabled={disabled} className={`${styles.button} ${state.isOpen ? styles.openBtn : ''} ${classNames?.root} ${isInvalid ? styles.invalid : ''} ${readOnly ? styles.readOnly : ''}`} data-testid={testId}>
          <span {...valueProps} className={`${styles.item} ${state.selectedItem ? '' : styles.placeholder} ${readOnly ? styles.readOnly : ''}`}>
            {state.selectedItem ? displayOption || state.selectedItem.rendered : placeholder}
          </span>

          <ChevronDownIcon pointUp={state.isOpen} className={classNames?.icon} />
        </button>}

      {errorMessage && <p className={styles.error}>{errorMessage}</p>}

      {state.isOpen && <Popover state={state} triggerRef={ref} placement="bottom" minWidth={ref.current?.offsetWidth}>
          <ListBox {...menuProps} state={state} />
        </Popover>}
    </FormControlWrapper>;
}