import { MAX_ADDRESS_LEN } from 'constants/general';
import React, { InputHTMLAttributes, ReactNode } from 'react';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import { ErrorMessage } from './ErrorMessage';
import { Icon } from './Icon';
import { Input } from './Input';
import { InputSuffix } from './InputSuffix';
import { InputWrapper } from './InputWrapper';
import { LabelBlock } from './LabelBlock';
import styles from './TextInput.module.scss';
export type InputVariant = 'solid' | 'outline';
export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  label?: string;
  error?: string;
  warning?: string;
  classNames?: Partial<{
    className: string;
    error: string;
    inputSuffix: string;
    input: string;
  }>;
  color?: string;
  variant?: InputVariant;

  /**
   * Check valid value
   */
  isValid?: boolean;
  withCopyToClipboard?: boolean;
  resetCopyToClipboard?: boolean;
  isCryptoAddress?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  className?: string;
  errorHighlight?: boolean;
  rightLabel?: string | React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({
  warning,
  label = '',
  rightLabel,
  error,
  classNames,
  color,
  variant = 'solid',
  isValid = false,
  withCopyToClipboard,
  value,
  isCryptoAddress,
  prefix,
  suffix,
  className,
  errorHighlight = false,
  ...rest
}, ref) => {
  return <div className={`${styles.formControlWrapper} ${className}`}>
        {(label || rightLabel) && <div className={styles.labelGroup}>
            {label && <LabelBlock className={`${styles.labelBlock} ${rightLabel ? styles.hasRightLabel : ''}`}>
                <label htmlFor={rest.id} className={styles.label}>
                  <span>{label}</span>
                </label>
              </LabelBlock>}
            {rightLabel}
          </div>}
        <InputWrapper>
          {prefix && <span className={styles.inputPrefix}>{prefix}</span>}

          <Input id={rest.id} ref={ref} value={value} variant={variant} isValid={isValid} maxLength={isCryptoAddress ? MAX_ADDRESS_LEN : rest.maxLength} hasPrefix={!!prefix} $hasRightIcon={withCopyToClipboard || Boolean(suffix)} hasError={Boolean(error?.length) || errorHighlight} hasWarning={!!warning} color={color} className={classNames?.input} {...rest} />

          {withCopyToClipboard && <Icon>
              <ClipboardCopy value={value?.toString() ?? ''} />
            </Icon>}

          {suffix && <InputSuffix className={classNames?.inputSuffix}>{suffix}</InputSuffix>}
        </InputWrapper>

        {(error || warning) && <ErrorMessage className={`${classNames?.error || ''} ${!error && warning ? styles.warning : ''}`}>
            {error || warning}
          </ErrorMessage>}
      </div>;
});
export default React.memo(TextInput);