import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import Button from 'components/Buttons/Button';
import { useSportCountDown } from 'hooks/useSportCountDown';
import useEventStartTimeDisplay from 'hooks/useEventStartTimeDisplay';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { checkEventStateBeforeLive } from 'utils/sportEventStatusCheck';
import colors from '../../../../assets/styles/colors';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import CurrencyInput from '../../../../components/FormControls/CurrencyInput';
import FormattedAmount from '../../../../components/FormattedAmount';
import { Currency, SportsMarketSelectionStatus, SportsMarketStatus, SportsMatchState } from '../../../../generated/graphql';
import { useDateFormat } from '../../../../hooks/format/useDateFormat';
import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { LiveMatchProgressText } from '../../../../utils/sports/getLiveMatchProgressText';
import { BetSlipProps } from '../BetsGroup.type';
import styles from './BetGameEventInfo.module.scss';
import { BetGameEventInfoLink } from './BetGameEventInfoLink';
import { BetGameEventInfoMultiple } from './BetGameEventInfoMultiple';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
import { getColorFormattedStartTime } from './getColorFormattedStartTime';
export type BetGameEventInfoProps = {
  bet: BetSlipItem;
  currency: Currency;
  hasMultiSelections?: boolean;
  oddsOutputText: string;
  onChangeBetAmount?: (cryptoValue: string, rawValue: string) => void;
  isLive?: boolean;
  isPreMatch?: boolean;
  isEventClosed?: boolean;
  allBetsResulted: boolean;
  estimatePayoutOutput: string;
  matchState?: SportsMatchState;
  setCollapse: React.Dispatch<React.SetStateAction<boolean[]>>;
  setAnimateLegIndex: React.Dispatch<React.SetStateAction<number>>;
  liveMatchProgressText: LiveMatchProgressText;
  collapses: boolean[];
  onMaxBetClickWithAmountUpdate?: () => void;
  maxBetLoading: boolean;
} & Pick<BetSlipProps, 'actions' | 'actionPanelData' | 'betIds' | 'betSlipStatus' | 'odds' | 'amount' | 'bets' | 'settlementPayout' | 'showOddsWarningMessage' | 'sportsBetStatus' | 'errors' | 'classNames' | 'matchStates' | 'maxBetEnabled'>;
export function BetGameEventInfo({
  betIds,
  betSlipStatus,
  setCollapse,
  hasMultiSelections,
  odds,
  oddsOutputText,
  allBetsResulted,
  bet: {
    id,
    startTime,
    name,
    provider,
    inPlayAllowed
  },
  bet,
  amount,
  currency,
  collapses,
  estimatePayoutOutput,
  showOddsWarningMessage = false,
  setAnimateLegIndex,
  onChangeBetAmount,
  sportsBetStatus,
  bets,
  isLive = false,
  isEventClosed = false,
  isPreMatch = false,
  errors,
  matchState,
  matchStates,
  actions,
  actionPanelData,
  classNames,
  liveMatchProgressText: {
    content: liveMatchProgressTextContent,
    parts: [time, text]
  },
  maxBetEnabled,
  maxBetLoading,
  onMaxBetClickWithAmountUpdate
}: BetGameEventInfoProps) {
  const {
    formatDate
  } = useDateFormat();
  const {
    t
  } = useTranslation();
  const formattedStartTime = formatDate(startTime, 'lll');
  const {
    countdownTime,
    countdownStart,
    minutesRemaining,
    shouldShowVisualTimer
  } = useSportCountDown({
    dateTime: startTime,
    formattedDateTime: formattedStartTime,
    shortened: true,
    isLive
  });
  const {
    startTimeForDisplay
  } = useEventStartTimeDisplay({
    startTime,
    inPlayAllowed,
    formattedDateTime: formattedStartTime,
    eventState: matchState?.matchSummary?.eventState,
    countdownStart,
    countdownTime
  });
  const viewConfig = getBetViewConfig({
    betSlipStatus,
    sportsBetStatus: hasMultiSelections ? sportsBetStatus : bet.sportsBetStatus,
    sportsBetSelectionStatus: bet.sportsBetSelectionStatus,
    isLive,
    isPreMatch,
    isBetPlacedError: errors?.placedBetErrorIds?.includes(bet.id)
  });
  const {
    shouldHideDate,
    isInBetAddOrConfirmed,
    isInBetConfirmedView,
    isInBetPlacingView,
    isInBetPendingView,
    isInBetAddView,
    isInBetInfoView,
    isInBetPlacedView,
    isVoid,
    isPushed,
    isDeadHeat,
    isCashedOut,
    isCanceled,
    isPending,
    isPartialPending,
    shouldShowReceipt,
    shouldShowStats,
    shouldShowLiveEventInfo
  } = viewConfig;
  const eventStateBeforeLive = checkEventStateBeforeLive({
    startTime,
    inPlayAllowed
  });
  const startTimeColor = getColorFormattedStartTime({
    isCanceled,
    isVoid,
    isPushed,
    isDeadHeat,
    isCashedOut,
    isPreMatch,
    isLive,
    countdownStart,
    betSlipStatus,
    isInBetPendingView,
    isPartialPending,
    isPending,
    isInBetInfoView,
    eventStateBeforeLive
  });
  const hasFooter = !isInBetAddView && (shouldShowReceipt || shouldShowStats);
  const hasMarketNotOpenInBetAdd = isInBetAddOrConfirmed && (isEventClosed || bet.marketStatus !== SportsMarketStatus.OPEN || bet.status !== SportsMarketSelectionStatus.TRADING);
  const disableInput = !betIds.length || hasMultiSelections && betIds.length < 2 || hasMarketNotOpenInBetAdd;
  return <div className={`${styles.betTicketBody} ${clsx({
    [String(styles.betTicketBodyNoRounded)]: hasFooter,
    [String(styles.betTicketBodyConfirmingView)]: isInBetConfirmedView || isInBetPlacingView,
    [String(styles.betTicketBodyPlacedView)]: isInBetPlacedView,
    [String(styles.shouldShowReceipt)]: !hasMultiSelections && shouldShowReceipt
  })} ${classNames?.betTicketBody || ''}`}>
      {hasMultiSelections && <BetGameEventInfoMultiple betSlipStatus={betSlipStatus} startTime={startTime} setCollapse={setCollapse} bets={bets} collapses={collapses} setAnimateLegIndex={setAnimateLegIndex} isVoid={isVoid} allBetsResulted={allBetsResulted} isInBetAddOrConfirmed={isInBetAddOrConfirmed} oddsOutputText={oddsOutputText} odds={odds} betIds={betIds} matchStates={matchStates} hasMarketNotOpenInBetAdd={hasMarketNotOpenInBetAdd} />}

      {!hasMultiSelections && <BetGameEventInfoLink isEventClosed={isEventClosed} actionPanelData={actionPanelData} bet={bet} odds={odds} matchState={matchState} viewConfig={viewConfig} hasMarketNotOpenInBetAdd={hasMarketNotOpenInBetAdd} name={name} errors={errors} provider={provider} showOddsWarningMessage={showOddsWarningMessage} shouldShowLiveEventInfo={shouldShowLiveEventInfo} shouldHideDate={shouldHideDate} time={time} text={text} startTimeColor={startTimeColor} startTimeForDisplay={startTimeForDisplay} liveMatchProgressTextContent={liveMatchProgressTextContent} shouldDisplayPieTimer={shouldShowVisualTimer} minutesRemaining={minutesRemaining} />}

      {isInBetAddView && <div className={isVoid ? styles.voided : ''}>
          {showOddsWarningMessage ? <div className={styles.acceptOddsButton}>
              <Button aria-label="accept odds change" onClick={hasMultiSelections ? actions?.onAcceptMultiNewOdds : () => actions?.onAcceptNewOdds?.(id)} value={t('btnAcceptNewOdds')} />
            </div> : <div className={clsx(styles.selectionField, {
        [String(styles.dimmed)]: hasMarketNotOpenInBetAdd
      })}>
              <div className={`${styles.betTicketAmountInput} ${maxBetEnabled ? styles.betTicketAmountInputWithMaxBet : ''}`}>
                <CurrencyInput onChange={onChangeBetAmount} value={amount} currency={currency} placeholder={t('phEnterStake')} hideRightLabel disabled={disableInput} onBlur={() => {
            actions?.onValidateBet?.({
              ...(hasMultiSelections ? {} : {
                bet
              })
            });
          }} maxBetEnabled={maxBetEnabled} maxBetLoading={maxBetLoading} onMaxBetClick={onMaxBetClickWithAmountUpdate} classNames={{
            input: styles.input
          }} />
              </div>

              <div className={styles.betTicketPayout}>
                <BetTicketText rightAlign color={colors.white}>
                  {t('lblEstPayout')}
                </BetTicketText>

                <BetTicketAmount isPayout={BigNumber(estimatePayoutOutput).isGreaterThan(0)}>
                  <CryptoIcon currency={currency} />
                  <FormattedAmount placement="left" value={estimatePayoutOutput} currency={currency} />
                </BetTicketAmount>
              </div>
            </div>}
        </div>}
    </div>;
}