import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
// Value is same currency as input
import React, { InputHTMLAttributes, ReactNode, useCallback, useImperativeHandle, useRef } from 'react';
import { CRYPTO_DECIMALS, CRYPTO_DECIMALS_PLACEHOLDER, FIAT_DECIMALS, FIAT_DECIMALS_PLACEHOLDER } from '../../constants/decimalPlaces';
import { Currency } from '../../generated/graphql';
import { useConversion } from '../../hooks/useConversion';
import { useFiatRate } from '../../hooks/useFiatRate';
import usePreference from '../../hooks/usePreference';
import { transformCurrencyInput } from '../../utils/format';
import CryptoIcon from '../CurrencyIcons/CryptoIcon';
import GeneralCurrencyIcon from '../CurrencyIcons/GeneralCurrencyIcon';
import { Flex } from '../Flex';
import { Tooltip } from '../Tooltip';
import { CurrencyInputElement } from './CurrencyInputElement';
import { ErrorMessage } from './ErrorMessage';
import { InputSuffix } from './InputSuffix';
import { InputWrapper } from './InputWrapper';
import { LabelBlock } from './LabelBlock';
import { InputVariant } from './TextInput';
import { Loader } from 'components/Loader';
import { preventNonNumericInput } from 'utils/inputs';
import styles from './CurrencyInput.module.scss';
export interface CurrencyInputRawProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  currency: Currency;
  error?: string;
  classNames?: Partial<{
    input: string;
    error: string;
  }>;
  rightLabel?: string;
  suffix?: ReactNode;
  disableInput?: boolean;
  cursorNotAllowed?: boolean;
  bgColor?: string;
  testId?: string;
  tooltip?: string;
  maxBetEnabled?: boolean;
  maxBetLoading?: boolean;
  onMaxBetClick?: () => void;
  /**
   * Control the dimmed state; given that we have some disabled
   * states that don't need to be dimmed.
   */
  dimmed?: boolean;
  alwaysDisplayCryptoIcon?: boolean;
  /**
   * If it's defined and greater than 0, it represents the payout that the user can earn
   */
  maxPayoutUSD?: number;
  variant?: InputVariant;
  overrideDisplayInFiat?: boolean;
}
const onWheel = (e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement)?.blur();
export const CurrencyInputRaw = React.forwardRef<HTMLInputElement, CurrencyInputRawProps>(({
  label,
  currency,
  value,
  rightLabel,
  suffix,
  error,
  classNames,
  onChange,
  onBlur,
  onFocus,
  testId,
  dimmed = false,
  disableInput = false,
  alwaysDisplayCryptoIcon = false,
  bgColor,
  maxPayoutUSD = 0,
  variant,
  cursorNotAllowed,
  tooltip,
  maxBetEnabled,
  maxBetLoading,
  onMaxBetClick,
  overrideDisplayInFiat,
  ...rest
}, ref) => {
  const {
    displayInFiat: displayInFiatPreference,
    fiatPreference
  } = usePreference();
  const {
    t
  } = useTranslation();
  let displayInFiat = displayInFiatPreference;
  if (overrideDisplayInFiat !== undefined) {
    displayInFiat = overrideDisplayInFiat;
  }
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => (innerRef.current as HTMLInputElement));
  if (!onChange && !(rest.readOnly || rest.disabled)) {
    throw new Error('CurrencyInput expects on chat event');
  }
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    if (onChange) {
      const transformedValue = transformCurrencyInput(event.target.value);

      // We need this check because for some reason without this condition and when type = number
      // if you type 1.5 and then backspace it does something funny
      if (event.target.value !== transformedValue) event.target.value = transformedValue;
      onChange(event);
    }
  }, [onChange]);
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(e => {
    e.target.value = e.target.value && BigNumber(e.target.value).toFixed(displayInFiat ? FIAT_DECIMALS : CRYPTO_DECIMALS);
    onChange?.(e);
    onBlur?.(e);
  }, [onBlur, onChange, displayInFiat]);
  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    innerRef.current?.select();
  }, [onFocus]);
  const defaultPlaceholder = displayInFiat ? FIAT_DECIMALS_PLACEHOLDER : CRYPTO_DECIMALS_PLACEHOLDER;
  const {
    fiatToCrypto
  } = useConversion(currency);
  const betLimitFiat = BigNumber(maxPayoutUSD).multipliedBy(useFiatRate());
  const maxPayoutFormatted = displayInFiat ? t('intlCurrencyWithOptions', {
    val: betLimitFiat.toString(),
    currency: fiatPreference,
    minimumFractionDigits: FIAT_DECIMALS,
    maximumFractionDigits: FIAT_DECIMALS
  }) : fiatToCrypto(betLimitFiat.toString());
  return <div className={clsx(styles.formControlWrapper, {
    [String(styles.dimmed)]: dimmed
  })}>
        {(label || rightLabel) && <LabelBlock className={styles.labelBlock}>
            {label && (maxPayoutUSD > 0 ? <label className={styles.labelLeft}>
                  <span>{label}</span>
                  <Tooltip content={<Flex align="center" gap="0.375rem">
                        <p>{t('maxPayout')}</p>
                        <GeneralCurrencyIcon />
                        <p>{maxPayoutFormatted}</p>
                      </Flex>}>
                    <div className={styles.maxPayoutInfo}>
                      <img src="/icons/bet-limit-info.svg" alt="info" />
                    </div>
                  </Tooltip>
                </label> : <label className={styles.labelLeft}>
                  <span>{label}</span>
                </label>)}

            {rightLabel && <p className={styles.labelRight}>{rightLabel}</p>}
          </LabelBlock>}

        <InputWrapper>
          <div className={styles.currencyInputIcon}>
            {alwaysDisplayCryptoIcon ? <CryptoIcon currency={currency} /> : <GeneralCurrencyIcon currency={currency} />}
          </div>

          <Tooltip content={tooltip} classNames={{
        root: styles.tooltipRoot,
        content: styles.tooltipContent
      }} placement="bottom" disabled={!tooltip}>
            <div className={clsx(styles.currencyInput, {
          [String(styles.cursorNotAllowed)]: cursorNotAllowed,
          [String(styles.maxBetEnabled)]: maxBetEnabled
        })}>
              <CurrencyInputElement ref={innerRef} variant={variant} disabled={disableInput} {...rest} className={classNames?.input} onWheel={onWheel} type="number" data-testid={testId} value={value} placeholder={rest.placeholder ?? defaultPlaceholder} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} onKeyDown={preventNonNumericInput} hasError={Boolean(error)} style={bgColor ? {
            backgroundColor: bgColor
          } : undefined} maxLength={rest.maxLength ?? 20} />
              {maxBetEnabled && <button disabled={maxBetLoading} type="button" onClick={onMaxBetClick} className={styles.maxButton}>
                  {maxBetLoading ? <Loader size="small" variant="circle" /> : t('max')}
                </button>}
            </div>
          </Tooltip>

          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputWrapper>

        {error && <ErrorMessage className={classNames?.error}>{error}</ErrorMessage>}
      </div>;
});