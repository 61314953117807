import { useCallback, useRef, useSyncExternalStore } from 'react';
import { DEFAULT_STORE } from './defaultExternalStore';

type Store = typeof DEFAULT_STORE;

const createStore = (initialState: Store) => {
  let state = initialState;
  const getState: () => Store = () => state;
  const listeners = new Set<() => void>();

  const setState = (dispatchAction: ((payload: Store) => Store) | Store) => {
    state = typeof dispatchAction === 'function' ? dispatchAction(state) : state;

    for (const listener of listeners) {
      listener();
    }
  };

  const subscribe = (listener: () => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  return { getState, setState, subscribe };
};

export const store = createStore(DEFAULT_STORE);

const setStateCallback = <T extends keyof Store>(
  storeKey: T,
  callback: (draftState: Store[T]) => Store[T],
) => {
  store.setState(state => {
    const stateCopy = { ...state };
    const result = callback(stateCopy[storeKey]);

    stateCopy[storeKey] = result;

    return stateCopy;
  });
};

export function useStore<T>(selector?: ((payload: Store) => T) | undefined): {
  state: T extends undefined ? undefined : T;
  setState: typeof setStateCallback;
  getState: () => Store;
} {
  const oldSelectedStateRef = useRef<T | undefined>(undefined);

  const getSnapshot = useCallback(() => {
    if (!selector) return;

    const currentStore = store.getState();
    const newSelectedState = selector(currentStore);

    const selectedStateHasChanged =
      JSON.stringify(oldSelectedStateRef.current) !== JSON.stringify(newSelectedState);

    // returns true if either the dependencies have changed or the selected state has changed
    if (selectedStateHasChanged) {
      oldSelectedStateRef.current = newSelectedState;
    }

    return oldSelectedStateRef.current;
  }, [selector]);

  useSyncExternalStore(store.subscribe, getSnapshot, () => undefined);
  const currentStore = store.getState();

  return {
    state: selector?.(currentStore) as T extends undefined ? undefined : T,
    setState: setStateCallback,
    getState: store.getState,
  };
}
