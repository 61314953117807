import { clientWithoutAuth } from 'apollo/clients';
import {
  BlackjackGameSetting,
  OriginalGame,
  OriginalGameSetting,
  useGetGlobalDataQuery,
} from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import { DEFAULT_SETTING } from 'redux/slices/appSettingSlice';

import { usePersistAppSetting } from './usePersistAppSetting';

export type GameSetting = Omit<OriginalGameSetting & BlackjackGameSetting, '__typename'>;
export function useGlobalData() {
  const { data } = useGetGlobalDataQuery({
    client: clientWithoutAuth,
    fetchPolicy: 'cache-and-network',
  });

  const { appSetting } = usePersistAppSetting({
    setting: data?.appSettings,
  });

  const getGameSetting = useCallback(
    (game: OriginalGame): GameSetting => {
      const settings = data?.appSettings ?? appSetting.details;

      const mapping: Record<OriginalGame, GameSetting> = {
        [OriginalGame.DICE]: settings.dice,
        [OriginalGame.HILO]: settings.hilo,
        [OriginalGame.LIMBO]: settings.limbo,
        [OriginalGame.MINES]: settings.mines,
        [OriginalGame.CRASH]: settings.crash,
        [OriginalGame.KENO]: settings.keno,
        [OriginalGame.PLINKO]: settings.plinko,
        [OriginalGame.BLACKJACK]: settings.blackjack,
        [OriginalGame.ROULETTE]: settings.roulette,
        [OriginalGame.WHEEL]: settings.wheel,
        [OriginalGame.TOWER]: settings.dice,
      };

      if (!mapping[game]) {
        return DEFAULT_SETTING;
      }
      return mapping[game];
    },
    [appSetting, data?.appSettings],
  );

  const getSportSetting = useCallback(() => {
    const settings = data?.appSettings;
    if (!settings) return { minBetUSD: 0, minPartialBetRatio: 0 };
    return settings.sportsBet;
  }, [data?.appSettings]);

  const minTipUsd = useMemo(() => {
    const settings = data?.appSettings;
    if (!settings) return 5;
    return settings.rain.minTipUSD;
  }, [data?.appSettings]);

  const getKycMinAgeByCountry = useCallback(
    (alpha3: string): number => {
      const settings = data?.appSettings;
      if (!settings) return 18;
      const minAge = settings.kycAge.find(ageReq => ageReq.country === alpha3)?.age;
      return minAge ?? 18;
    },
    [data?.appSettings],
  );

  return {
    ...data,
    getGameSetting,
    getSportSetting,
    minTipUsd,
    getKycMinAgeByCountry,
  };
}
