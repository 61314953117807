import { SportsMatchPhase, SportsMatchPhaseLabel } from '../../generated/graphql';

export const MATCH_PHASE_LIST: Record<SportsMatchPhase, string> = {
  [SportsMatchPhase.PREMATCH]: 'txtSportLiveLabelsPreMatch',
  [SportsMatchPhase.FIRSTHALF]: 'txtFirstHalf',
  [SportsMatchPhase.HALFTIME]: 'txtSportLiveLabelsHalftime',
  [SportsMatchPhase.SECONDHALF]: 'txtSecondHalf',
  [SportsMatchPhase.FULLTIMENORMALTIME]: 'txtSportLiveLabelsFullTime',
  [SportsMatchPhase.EXTRATIMEFIRSTHALF]: 'txtExtraTimeFirstHalf',
  [SportsMatchPhase.EXTRATIMEHALFTIME]: 'txtSportLiveLabelsExtraTimeHalftime',
  [SportsMatchPhase.EXTRATIMESECONDHALF]: 'txtExtraTimeSecondHalf',
  [SportsMatchPhase.FULLTIMEEXTRATIME]: 'txtSportLiveLabelsFullTime',
  [SportsMatchPhase.PENALTIES]: 'txtSportLiveLabelsPenalties',
  [SportsMatchPhase.POSTMATCH]: 'txtPostMatch',
  [SportsMatchPhase.MATCHABANDONED]: 'txtMatchAbandoned',
  [SportsMatchPhase.PREGAME]: 'txtSportLiveLabelsPreGame',
  [SportsMatchPhase.FIRSTQUARTER]: 'txtFirstQuarter',
  [SportsMatchPhase.BEFORESECONDQUARTER]: 'txtSecondQuarter',
  [SportsMatchPhase.SECONDQUARTER]: 'txtSecondQuarter',
  [SportsMatchPhase.THIRDQUARTER]: 'txtThirdQuarter',
  [SportsMatchPhase.BEFOREFOURTHQUARTER]: 'txtFourthQuarter',
  [SportsMatchPhase.FOURTHQUARTER]: 'txtFourthQuarter',
  [SportsMatchPhase.BEFOREOVERTIME]: 'txtSportLiveLabelsOvertime',
  [SportsMatchPhase.OVERTIME]: 'txtSportLiveLabelsOvertime',
  [SportsMatchPhase.POSTGAME]: 'txtPostGame',
  [SportsMatchPhase.GAMEABANDONED]: 'txtGameAbandoned',
  [SportsMatchPhase.FIRSTPERIOD]: 'txtFirstPeriod',
  [SportsMatchPhase.ENDOFFIRSTPERIOD]: 'txtEndOfFirstPeriod',
  [SportsMatchPhase.SECONDPERIOD]: 'txtSecondPeriod',
  [SportsMatchPhase.ENDOFSECONDPERIOD]: 'txtEndOfSecondPeriod',
  [SportsMatchPhase.THIRDPERIOD]: 'txtThirdPeriod',
  [SportsMatchPhase.ENDOFTHIRDPERIOD]: 'txtEndOfThirdPeriod',
  [SportsMatchPhase.OVERTIMEPERIOD]: 'txtSportLiveLabelsOvertime',
  [SportsMatchPhase.ENDOFOVERTIMEPERIOD]: 'txtSportLiveLabelsOvertime',
  [SportsMatchPhase.PENALTYSHOOTOUT]: 'txtSportLiveLabelsPenalties',
  [SportsMatchPhase.SUSPENDED]: 'txtSuspended',
  [SportsMatchPhase.ABANDONED]: 'txtAbandoned',
  [SportsMatchPhase.INPROGRESS]: 'txtInProgress',
  [SportsMatchPhase.CANCELLED]: 'txtCancelled',
  [SportsMatchPhase.POSTPONED]: 'txtPostponed',
  [SportsMatchPhase.INPLAY]: 'txtInPlay',
  [SportsMatchPhase.BREAKINPLAY]: 'txtBreakInPlay',
  [SportsMatchPhase.COMPLETED]: 'txtCompleted',
  [SportsMatchPhase.FIRSTMAP]: 'txtFirstMap',
  [SportsMatchPhase.SECONDMAP]: 'txtSecondMap',
  [SportsMatchPhase.THIRDMAP]: 'txtThirdMap',
  [SportsMatchPhase.FOURTHMAP]: 'txtFourthMap',
  [SportsMatchPhase.FIFTHMAP]: 'txtFifthMap',
  [SportsMatchPhase.SIXTHMAP]: 'txtSixthMap',
  [SportsMatchPhase.SEVENTHMAP]: 'txtSeventhMap',
  [SportsMatchPhase.FIRSTINNING]: 'txtFirstInning',
  [SportsMatchPhase.SECONDINNING]: 'txtSecondInning',
  [SportsMatchPhase.THIRDINNING]: 'txtThirdInning',
  [SportsMatchPhase.FOURTHINNING]: 'txtFourthInning',
  [SportsMatchPhase.DELAYED]: 'txtDelayed',
  [SportsMatchPhase.CLOSED]: 'txtClosed',
  [SportsMatchPhase.NOTSTARTED]: 'txtNotStarted',
  [SportsMatchPhase.FIRSTGAME]: 'txtFirstGame',
  [SportsMatchPhase.SECONDGAME]: 'txtSecondGame',
  [SportsMatchPhase.THIRDGAME]: 'txtThirdGame',
  [SportsMatchPhase.FOURTHGAME]: 'txtFourthGame',
  [SportsMatchPhase.FIFTHGAME]: 'txtFifthGame',
  [SportsMatchPhase.SIXTHGAME]: 'txtSixthGame',
  [SportsMatchPhase.SEVENTHGAME]: 'txtSeventhGame',
};

export const MATCH_PHASE_LABEL_LIST = {
  [SportsMatchPhaseLabel.INNING]: 'txtInning',
  [SportsMatchPhaseLabel.GAME]: 'txtGame',
  [SportsMatchPhaseLabel.SET]: 'txtSet',
  [SportsMatchPhaseLabel.ROUND]: 'txtRound',
  [SportsMatchPhaseLabel.LAP]: 'txtLap',
};
