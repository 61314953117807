import Button from 'components/Buttons/Button';
import { GeneralModalIcon, ModalProps, ModalTypes } from 'constants/modal';
import Link from 'next/link';
import React, { Dispatch, SetStateAction } from 'react';
import { MODAL_NAME, Modal } from '../../Modal/Modal';
import styles from './GenericModal.module.scss';
import { ButtonGroup } from './ButtonGroup';
import { Header } from './Header';
import { IconContainer } from './IconContainer';
import { LinkTarget } from './LinkTarget';
import { Body } from './body';
const icons: { [K in GeneralModalIcon]: JSX.Element } = {
  success: <img src="/icons/check.svg" alt="check" />,
  verification: <img src="/icons/article.svg" alt="article" />,
  logout: <img src="/icons/user-logout.svg" alt="user" />,
  mail: <img src="/icons/mail.svg" alt="mail" />,
  new: <img src="/icons/new.svg" alt="new" />
};
export type GeneralModalType = {
  type: ModalTypes | '';
  config: ModalProps & {
    imgIcon?: React.ReactNode;
    custom?: boolean;
  };
};
export type GeneralModalProps = {
  classNames?: {
    body?: string;
  };
  currentModal: GeneralModalType;
  setCurrentModal: Dispatch<SetStateAction<GeneralModalType>>;
  children?: React.ReactNode;
};
const GeneralModal = ({
  currentModal,
  setCurrentModal,
  classNames,
  children
}: GeneralModalProps) => {
  const {
    body,
    icon,
    imgIcon,
    cancelBtnText,
    confirmBtnText,
    header,
    link,
    linkText,
    onCancel,
    onConfirm
  } = currentModal.config;
  const bodyComponents = (currentModal.config.body ?? '').split('**').map((text, index) => index % 2 === 1 ? <strong key={index}>{text}</strong> : <span key={index}>{text}</span>);
  return <Modal isOpen={currentModal.type === ModalTypes.GENERAL} onClose={() => {
    setCurrentModal(data => ({
      ...data,
      type: ''
    }));
  }} target={MODAL_NAME}>
      <div className={styles.generalModalContainer}>
        {children ? children : <>
            {(icon || imgIcon) && <IconContainer>{imgIcon || icon && icons[icon]}</IconContainer>}
            <Header>{header}</Header>
            {body && <Body className={classNames?.body}>{bodyComponents}</Body>}
            {(confirmBtnText || cancelBtnText) && <ButtonGroup>
                {cancelBtnText && <Button size="lg" variant="tertiary" value={cancelBtnText} onClick={onCancel} />}
                {confirmBtnText && <Button size="lg" value={confirmBtnText} onClick={onConfirm} />}
              </ButtonGroup>}
            {linkText && <Link href={link || '/'} passHref legacyBehavior>
                <a>
                  <LinkTarget onClick={() => setCurrentModal(data => ({
              ...data,
              type: ''
            }))}>
                    {linkText}
                  </LinkTarget>
                </a>
              </Link>}
          </>}
      </div>
    </Modal>;
};
export default GeneralModal;